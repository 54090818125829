import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../core/store';

const passiveCycleReducer = (state: RootState) => state.private.passiveCycle;

export const passiveCycleOptionsSelector = createSelector(
  passiveCycleReducer,
  (passiveCycle) => passiveCycle.passiveCycleOptions
);

export const ordersSelector = createSelector(passiveCycleReducer, (passiveCycle) => passiveCycle.orders);

export const ordersForBillsSelector = createSelector(
  passiveCycleReducer,
  (passiveCycle) => passiveCycle.ordersForBills
);

export const filtersOrdersUsedSelector = createSelector(
  passiveCycleReducer,
  (passiveCycle) => passiveCycle.filtersOrdersUsed
);

export const associableOtherCostsProjectsSelector = createSelector(
  passiveCycleReducer,
  (passiveCycle) => passiveCycle.associableOtherCostsProjects
);

export const suppliersSelector = createSelector(passiveCycleReducer, (passiveCycle) => passiveCycle.suppliers);
