import { Input, Select } from 'antd';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import LinksButton from '../../../../../shared/design-system/components/links-button';
import { FornitoreDto } from '@api/client';
import { Dispatch, SetStateAction } from 'react';
import { useAppDispatch } from '../../../../../core/store/hooks';
import { createSupplier, getSupplierById, updateSupplier } from '../../_redux/actions';
import { Button, Col, Row } from 'react-bootstrap';
import { AppToastService } from '../../../../../shared/design-system/components/app-toast/app-toast.service';
import { filterOption } from '../../../../../shared/utils/common.utils';

interface Props {
  editMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  supplier: FornitoreDto | undefined;
  setSupplier: Dispatch<SetStateAction<FornitoreDto | undefined>>;
}

const SuppliersCrudForm: React.FC<Props> = ({ editMode, setEditMode, supplier, setSupplier }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleSubmit = (values: FornitoreDto, { resetForm }: any) => {
    if (editMode) {
      dispatch(updateSupplier({ fornitoreDto: values }))
        .unwrap()
        .then((res) => {
          if (res) {
            setEditMode(false);
            setSupplier(undefined);
            dispatch(getSupplierById(values.id!));
            AppToastService.success(`Supplier ${values.denominazione} updated successfully`);
            resetForm();
          }
        });
    } else {
      dispatch(createSupplier({ fornitoreDto: values }))
        .unwrap()
        .then((res) => {
          if (res) {
            setSupplier(undefined);
            dispatch(getSupplierById(res.id!));
            AppToastService.success(`Supplier ${values.denominazione} created successfully`);
            resetForm();
          }
        });
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        id: supplier?.id || undefined,
        denominazione: supplier?.denominazione || undefined,
        partitaIva: supplier?.partitaIva || undefined,
        sedeLegale: supplier?.sedeLegale || undefined,
        sedeOperativa: supplier?.sedeOperativa || undefined,
        numeroTelefono: supplier?.numeroTelefono || undefined,
        email: supplier?.email || undefined,
        pec: supplier?.pec || undefined,
        sitoWeb: supplier?.sitoWeb || undefined,
        performance: supplier?.performance || undefined,
        feedbackInterno: supplier?.feedbackInterno || undefined,
        nonConformita: supplier?.nonConformita || undefined,
        indiceAffidabilita: supplier?.indiceAffidabilita || undefined,
        categoriaMerceologica: supplier?.categoriaMerceologica?.id || undefined,
        note: supplier?.note || undefined,
      }}
      enableReinitialize
    >
      {({ handleSubmit: _handleSubmit, handleChange, setFieldValue, values, touched, errors, resetForm }) => {
        return (
          <form onSubmit={_handleSubmit}>
            <Row>
              {editMode && (
                <Col lg={3} md={9} sm={12} className="mt-3">
                  <div className="form-group">
                    <label htmlFor="id">{t('ID')}</label>
                    <Input type="text" id="id" name="id" size="large" value={values.id} disabled={true} />
                  </div>
                </Col>
              )}

              <Col lg={3} md={9} sm={12} className="mt-3">
                <div className="form-group">
                  <label htmlFor="denominazione">{t('Name')}</label>
                  <Input
                    required
                    allowClear
                    placeholder={t('Insert name')}
                    type="text"
                    id="denominazione"
                    name="denominazione"
                    size="large"
                    value={values.denominazione}
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col lg={3} md={9} sm={12} className="mt-3">
                <div className="form-group">
                  <label htmlFor="partitaIva">{t('VAT')}</label>
                  <Input
                    allowClear
                    placeholder={t('Insert VAT')}
                    type="text"
                    id="partitaIva"
                    name="partitaIva"
                    size="large"
                    value={values.partitaIva}
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col lg={3} md={9} sm={12} className="mt-3">
                <div className="form-group">
                  <label htmlFor="sedeLegale">{t('Legal address')}</label>
                  <Input
                    allowClear
                    placeholder={t('Insert legal address')}
                    type="text"
                    id="sedeLegale"
                    name="sedeLegale"
                    size="large"
                    value={values.sedeLegale}
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col lg={3} md={9} sm={12} className="mt-3">
                <div className="form-group">
                  <label htmlFor="sedeOperativa">{t('Operative address')}</label>
                  <Input
                    allowClear
                    placeholder={t('Insert operative address')}
                    type="text"
                    id="sedeOperativa"
                    name="sedeOperativa"
                    size="large"
                    value={values.sedeOperativa}
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col lg={3} md={9} sm={12} className="mt-3">
                <div className="form-group">
                  <label htmlFor="numeroTelefono">{t('Phone number')}</label>
                  <Input
                    allowClear
                    placeholder={t('Insert phone number')}
                    type="text"
                    id="numeroTelefono"
                    name="numeroTelefono"
                    size="large"
                    value={values.numeroTelefono}
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col lg={3} md={9} sm={12} className="mt-3">
                <div className="form-group">
                  <label htmlFor="email">{t('Email')}</label>
                  <Input
                    allowClear
                    placeholder={t('Insert email')}
                    type="text"
                    id="email"
                    name="email"
                    size="large"
                    value={values.email}
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col lg={3} md={9} sm={12} className="mt-3">
                <div className="form-group">
                  <label htmlFor="pec">{t('PEC')}</label>
                  <Input
                    allowClear
                    placeholder={t('Insert PEC')}
                    type="text"
                    id="pec"
                    name="pec"
                    size="large"
                    value={values.pec}
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col lg={3} md={9} sm={12} className="mt-3">
                <div className="form-group">
                  <label htmlFor="sitoWeb">{t('Website')}</label>
                  <Input
                    allowClear
                    placeholder={t('Insert website')}
                    type="text"
                    id="sitoWeb"
                    name="sitoWeb"
                    size="large"
                    value={values.sitoWeb}
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col lg={3} md={9} sm={12} className="mt-3">
                <div className="form-group">
                  <label htmlFor="performance">{t('Performance')}</label>
                  <Input
                    allowClear
                    placeholder={t('Insert performance')}
                    type="text"
                    id="performance"
                    name="performance"
                    size="large"
                    value={values.performance}
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col lg={3} md={9} sm={12} className="mt-3">
                <div className="form-group">
                  <label htmlFor="feedbackInterno">{t('Internal feedback')}</label>
                  <Input
                    allowClear
                    placeholder={t('Insert internal feedback')}
                    type="text"
                    id="feedbackInterno"
                    name="feedbackInterno"
                    size="large"
                    value={values.feedbackInterno}
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col lg={3} md={9} sm={12} className="mt-3">
                <div className="form-group">
                  <label htmlFor="nonConformita">{t('Non-conformity')}</label>
                  <Input
                    allowClear
                    placeholder={t('Insert non-conformity')}
                    type="text"
                    id="nonConformita"
                    name="nonConformita"
                    size="large"
                    value={values.nonConformita}
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col lg={3} md={9} sm={12} className="mt-3">
                <div className="form-group">
                  <label htmlFor="indiceAffidabilita">{t('Reliability index')}</label>
                  <Input
                    allowClear
                    placeholder={t('Insert reliability index')}
                    type="text"
                    id="indiceAffidabilita"
                    name="indiceAffidabilita"
                    size="large"
                    value={values.indiceAffidabilita}
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col lg={3} md={9} sm={12} className="mt-3">
                <div className="form-group">
                  <label htmlFor="categoriaMerceologica">{t('Category')}</label>
                  <Select
                    allowClear
                    showSearch
                    className="w-100"
                    placeholder={t('Insert category')}
                    id="categoriaMerceologica"
                    size="large"
                    optionFilterProp="children"
                    filterOption={filterOption}
                    value={values.categoriaMerceologica}
                    onChange={handleChange}
                    options={[]}
                  />
                </div>
              </Col>
              <Col lg={12} md={9} sm={12} className="mt-3">
                <div className="form-group">
                  <label htmlFor="note">{t('Notes')}</label>
                  <Input.TextArea
                    allowClear
                    placeholder={t('Insert notes')}
                    id="note"
                    name="note"
                    value={values.note}
                    onChange={handleChange}
                  />
                </div>
              </Col>

              <Col className="align-self-end mb-1 mt-3" lg={6} md={9} sm={12} xs={12}>
                <LinksButton type="submit" className="btn btn-links" isDisabled={!values.denominazione}>
                  {editMode ? t('Update') : t('Create')}
                </LinksButton>
                {editMode && (
                  <Button
                    className="btn text-danger trash-btn"
                    type="button"
                    variant="icon"
                    onClick={() => {
                      setEditMode(false);
                      setSupplier(undefined);
                    }}
                  >
                    <i className="fa-regular fa-trash-can me-2"></i>
                    {t('Exit edit mode')}
                  </Button>
                )}
              </Col>
            </Row>
          </form>
        );
      }}
    </Formik>
  );
};

export default SuppliersCrudForm;
