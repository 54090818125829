/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FatturaDettaglioDtoFromJSON, FatturaDettaglioDtoToJSON, } from './FatturaDettaglioDto';
/**
 * Check if a given object implements the FattureProgettoDto interface.
 */
export function instanceOfFattureProgettoDto(value) {
    return true;
}
export function FattureProgettoDtoFromJSON(json) {
    return FattureProgettoDtoFromJSONTyped(json, false);
}
export function FattureProgettoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'pkFattura': json['pkFattura'] == null ? undefined : json['pkFattura'],
        'dettagli': json['dettagli'] == null ? undefined : (json['dettagli'].map(FatturaDettaglioDtoFromJSON)),
    };
}
export function FattureProgettoDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'pkFattura': value['pkFattura'],
        'dettagli': value['dettagli'] == null ? undefined : (value['dettagli'].map(FatturaDettaglioDtoToJSON)),
    };
}
