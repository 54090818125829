/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AssociazioniFattureOrdiniDtoFromJSON, AssociazioniFattureOrdiniDtoToJSON, } from './AssociazioniFattureOrdiniDto';
/**
 * Check if a given object implements the FatturaRicevutaDto interface.
 */
export function instanceOfFatturaRicevutaDto(value) {
    return true;
}
export function FatturaRicevutaDtoFromJSON(json) {
    return FatturaRicevutaDtoFromJSONTyped(json, false);
}
export function FatturaRicevutaDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'fkAziendaAlyante': json['fkAziendaAlyante'] == null ? undefined : json['fkAziendaAlyante'],
        'fkAzienda': json['fkAzienda'] == null ? undefined : json['fkAzienda'],
        'fkFatturaRicevuta': json['fkFatturaRicevuta'] == null ? undefined : json['fkFatturaRicevuta'],
        'denominazioneAzienda': json['denominazioneAzienda'] == null ? undefined : json['denominazioneAzienda'],
        'frCodice': json['frCodice'] == null ? undefined : json['frCodice'],
        'frCausale': json['frCausale'] == null ? undefined : json['frCausale'],
        'frDescrizioneCausale': json['frDescrizioneCausale'] == null ? undefined : json['frDescrizioneCausale'],
        'frTipologia': json['frTipologia'] == null ? undefined : json['frTipologia'],
        'fkFornitore': json['fkFornitore'] == null ? undefined : json['fkFornitore'],
        'denominazioneFornitore': json['denominazioneFornitore'] == null ? undefined : json['denominazioneFornitore'],
        'dataFattura': json['dataFattura'] == null ? undefined : (new Date(json['dataFattura'])),
        'frProtocollo': json['frProtocollo'] == null ? undefined : json['frProtocollo'],
        'frNumDocumento': json['frNumDocumento'] == null ? undefined : json['frNumDocumento'],
        'frCodicePagamento': json['frCodicePagamento'] == null ? undefined : json['frCodicePagamento'],
        'frModalitaPagamento': json['frModalitaPagamento'] == null ? undefined : json['frModalitaPagamento'],
        'frReverseCharge': json['frReverseCharge'] == null ? undefined : json['frReverseCharge'],
        'frNotaDiCredito': json['frNotaDiCredito'] == null ? undefined : json['frNotaDiCredito'],
        'frImponibile': json['frImponibile'] == null ? undefined : json['frImponibile'],
        'frImportoTotale': json['frImportoTotale'] == null ? undefined : json['frImportoTotale'],
        'frImposta': json['frImposta'] == null ? undefined : json['frImposta'],
        'associatedOrders': json['associatedOrders'] == null ? undefined : (json['associatedOrders'].map(AssociazioniFattureOrdiniDtoFromJSON)),
    };
}
export function FatturaRicevutaDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'fkAziendaAlyante': value['fkAziendaAlyante'],
        'fkAzienda': value['fkAzienda'],
        'fkFatturaRicevuta': value['fkFatturaRicevuta'],
        'denominazioneAzienda': value['denominazioneAzienda'],
        'frCodice': value['frCodice'],
        'frCausale': value['frCausale'],
        'frDescrizioneCausale': value['frDescrizioneCausale'],
        'frTipologia': value['frTipologia'],
        'fkFornitore': value['fkFornitore'],
        'denominazioneFornitore': value['denominazioneFornitore'],
        'dataFattura': value['dataFattura'] == null ? undefined : ((value['dataFattura']).toISOString().substring(0, 10)),
        'frProtocollo': value['frProtocollo'],
        'frNumDocumento': value['frNumDocumento'],
        'frCodicePagamento': value['frCodicePagamento'],
        'frModalitaPagamento': value['frModalitaPagamento'],
        'frReverseCharge': value['frReverseCharge'],
        'frNotaDiCredito': value['frNotaDiCredito'],
        'frImponibile': value['frImponibile'],
        'frImportoTotale': value['frImportoTotale'],
        'frImposta': value['frImposta'],
        'associatedOrders': value['associatedOrders'] == null ? undefined : (value['associatedOrders'].map(AssociazioniFattureOrdiniDtoToJSON)),
    };
}
