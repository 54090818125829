/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the PagingDto interface.
 */
export function instanceOfPagingDto(value) {
    return true;
}
export function PagingDtoFromJSON(json) {
    return PagingDtoFromJSONTyped(json, false);
}
export function PagingDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'size': json['size'] == null ? undefined : json['size'],
        'page': json['page'] == null ? undefined : json['page'],
        'totalElements': json['totalElements'] == null ? undefined : json['totalElements'],
        'totalPages': json['totalPages'] == null ? undefined : json['totalPages'],
    };
}
export function PagingDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'size': value['size'],
        'page': value['page'],
        'totalElements': value['totalElements'],
        'totalPages': value['totalPages'],
    };
}
