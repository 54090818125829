import { MeseAnnoLight } from '@api/client';
import { CheckboxValueType } from 'antd/es/checkbox/Group';

export const formatCurrency = (value: number | undefined, decimals = 2) => {
  value = !value ? 0 : value;
  return Number(value?.toFixed(decimals))?.toLocaleString('it-IT', {
    minimumFractionDigits: decimals,
  });
};
//versione per gli input num
export const formatCurrencyNumber = (value: number | undefined, decimals = 2) => {
  value = !value ? 0 : value;
  const factor = Math.pow(10, decimals);
  return Math.ceil(value * factor) / factor;
};
export const upperSnakeCaseFormatter = (value: any) => {
  if (!value) return '';
  const words = value
    .toLowerCase()
    .split('_')
    .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1));
  return words.join(' ');
};

export const compareByKeys = (key: string, order = 'asc') => {
  return function innerSort(a: any, b: any) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }

    const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === 'desc' ? comparison * -1 : comparison;
  };
};

export const getFormattedDateHeader = (month: number | undefined, year: number | undefined) => {
  const monthNames = [
    { month: 1, name: 'Jan' },
    { month: 2, name: 'Feb' },
    { month: 3, name: 'Mar' },
    { month: 4, name: 'Apr' },
    { month: 5, name: 'May' },
    { month: 6, name: 'Jun' },
    { month: 7, name: 'Jul' },
    { month: 8, name: 'Aug' },
    { month: 9, name: 'Sep' },
    { month: 10, name: 'Oct' },
    { month: 11, name: 'Nov' },
    { month: 12, name: 'Dec' },
  ];
  return `${monthNames.find((el) => el.month === month)?.name} '${String(year).substring(2)}`;
};

export const getFormattedDateString = (month: number | undefined | null, year?: number | undefined) => {
  const monthNames = getMonths();
  return `${monthNames.find((el) => el.month === month)?.name} ${year ? String(year) : ''}`;
};

export const getMonths = () => {
  return [
    { month: 1, name: 'January' },
    { month: 2, name: 'February' },
    { month: 3, name: 'March' },
    { month: 4, name: 'April' },
    { month: 5, name: 'May' },
    { month: 6, name: 'June' },
    { month: 7, name: 'July' },
    { month: 8, name: 'August' },
    { month: 9, name: 'September' },
    { month: 10, name: 'October' },
    { month: 11, name: 'November' },
    { month: 12, name: 'December' },
  ];
};

export const formatTitle = (title: string, maxLength: number, numTrim: number) => {
  return title.length > maxLength ? title.slice(0, numTrim) + '...' : title;
};

export const convertDateToIt = (data: Date, formato: string = ''): string => {
  const giorniSettimanaCompleti: string[] = [
    'domenica',
    'lunedì',
    'martedì',
    'mercoledì',
    'giovedì',
    'venerdì',
    'sabato',
  ];
  const mesiCompleti: string[] = [
    'gennaio',
    'febbraio',
    'marzo',
    'aprile',
    'maggio',
    'giugno',
    'luglio',
    'agosto',
    'settembre',
    'ottobre',
    'novembre',
    'dicembre',
  ];

  const giorniSettimana =
    formato === 'short' ? giorniSettimanaCompleti.map((giorno) => giorno.slice(0, 3)) : giorniSettimanaCompleti;
  const mesi = formato === 'short' ? mesiCompleti.map((mese) => mese.slice(0, 3)) : mesiCompleti;

  const giornoSettimana: string = giorniSettimana[data.getDay()];
  const giorno: number = data.getDate();
  const mese: string = mesi[data.getMonth()];
  const anno: number = data.getFullYear();

  const dataItaliana: string = `${giornoSettimana} ${giorno} ${mese} ${anno}`;
  return dataItaliana;
};

export const sortByMonthAndYear = (array: any[]) => {
  return array.sort((a, b) => {
    // Ordina per anno
    if (a.year && b.year && a.year !== b.year) {
      return a.year - b.year;
    }
    // Se gli anni sono uguali, ordina per mese
    return (a.month ?? 0) - (b.month ?? 0);
  });
};

export const filterOption = (input: string, option?: { children: string | undefined; value: number | undefined }) => {
  return (option?.children ?? '').toLowerCase().includes(input.toLowerCase());
};

export const generateMeseAnnoLightArray = (dateRange: [string, string]) => {
  const [start, end] = dateRange;
  if (!start || !end) {
    return [];
  }

  const [startMonth, startYear] = start.split('/').map(Number);
  const [endMonth, endYear] = end.split('/').map(Number);

  let result: MeseAnnoLight[] = [];
  let currentYear = startYear;
  let currentMonth = startMonth;

  while (currentYear < endYear || (currentYear === endYear && currentMonth <= endMonth)) {
    result.push({ mese: currentMonth, anno: currentYear });

    currentMonth++;
    if (currentMonth > 12) {
      currentMonth = 1;
      currentYear++;
    }
  }

  return result;
};

export const getMonthsToDelete = (checkedList: CheckboxValueType[]) => {
  let stringArray: string[] = checkedList.filter((item): item is string => typeof item === 'string');

  // Eliminare i duplicati
  let uniqueArray: string[] = [];
  stringArray.forEach((item) => {
    if (!uniqueArray.includes(item)) {
      uniqueArray.push(item);
    }
  });

  // Mappare le stringhe in oggetti
  let monthsYears = uniqueArray.map((dateStr) => {
    let [month, year] = dateStr.split(' ');
    let monthNumber;

    // Convertire il nome del mese in numero
    switch (month) {
      case 'January':
        monthNumber = 1;
        break;
      case 'February':
        monthNumber = 2;
        break;
      case 'March':
        monthNumber = 3;
        break;
      case 'April':
        monthNumber = 4;
        break;
      case 'May':
        monthNumber = 5;
        break;
      case 'June':
        monthNumber = 6;
        break;
      case 'July':
        monthNumber = 7;
        break;
      case 'August':
        monthNumber = 8;
        break;
      case 'September':
        monthNumber = 9;
        break;
      case 'October':
        monthNumber = 10;
        break;
      case 'November':
        monthNumber = 11;
        break;
      case 'December':
        monthNumber = 12;
        break;
    }

    return { month: monthNumber, year: parseInt(year) };
  });
  return monthsYears;
};
