import { DettaglioProjectDto, FilterDto, FilterDtoTipologiaEnum, ProgettoDto, SearchFilterDto } from '@api/client';
import { createReducer } from '@reduxjs/toolkit';
import {
  getFilteredProjects,
  getProjectDetails,
  getProjectSearchFilter,
  progettiGetlist,
  resetCurrentProject,
  saveFiltersUsed,
} from './actions';

interface InitialState {
  progettiFiltrati: ProgettoDto[];
  progettiInEvidenza: ProgettoDto[];
  filters: SearchFilterDto | null;
  currentProject: DettaglioProjectDto | null;
  currentProjectType: FilterDtoTipologiaEnum | null;
  filtersUsed: FilterDto | null;
}

const initialState: InitialState = {
  progettiFiltrati: [],
  progettiInEvidenza: [],
  filters: null,
  currentProject: null,
  currentProjectType: null,
  filtersUsed: null,
};

export const projectListReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(progettiGetlist.fulfilled, (state, action) => {
      state.progettiInEvidenza = action.payload?.listaProgetti || [];
    })
    .addCase(getProjectSearchFilter.fulfilled, (state, action) => {
      state.filters = action.payload || null;
    })
    .addCase(getProjectDetails.fulfilled, (state, action) => {
      state.currentProject = action.payload || null;
      state.currentProjectType = action.payload?.dettaglioOfferta?.categoria || null;
    })
    .addCase(getFilteredProjects.fulfilled, (state, action) => {
      state.progettiFiltrati = action.payload?.listaProgetti || [];
    })
    .addCase(saveFiltersUsed, (state, action) => {
      state.filtersUsed = action.payload;
    })
    .addCase(resetCurrentProject, (state) => {
      state.currentProject = null;
    });
});
