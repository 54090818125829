import { Formik } from 'formik';
import LinksButton from '../../../../shared/design-system/components/links-button';
import { useAppSelector } from '../../../../core/store/hooks';
import { DatePicker, Form, Input, Select } from 'antd';
import { filterOption } from '../../../../shared/utils/common.utils';
import { useTranslation } from 'react-i18next';
import { allUsersSelector } from '../../project-list/project-detail/_redux/selectors';
import dayjs from 'dayjs';
import { crudProjectSelector, filtriCrudSelector } from '../_redux/selectors';
import { typologiesData } from '../../../../enums/typologies';
import { StatusBadgeData } from '../../../../enums/status';
import useFormCrud from '../_hooks/useFormCrud';
import AppLoader from '../../../../shared/design-system/components/app-loader';
import { Card, Col, Row } from 'react-bootstrap';
import ModalAction from '../../project-list/project-detail/_components/modal-action';
import useCloseRendicontation from '../_hooks/useCloseRendicontation';
import FormBootstrap from 'react-bootstrap/Form';
import DelegateManagement from '../../../../shared/_components/delegate-management';
import { DelegateTypesEnum } from '../../../../enums/global.enum';

const ProjectCrud: React.FC = () => {
  const projectWrapper = useAppSelector(crudProjectSelector);
  const allUsers = useAppSelector(allUsersSelector);
  const crudFilters = useAppSelector(filtriCrudSelector);
  const { handleSubmit, initialValues, states, types } = useFormCrud(allUsers, crudFilters, projectWrapper);
  const {
    closeReporting,
    showModalCloseReporting,
    setshowModalCloseReporting,
    createIntranetProject,
    showModalOpenIntranetProject,
    setshowModalOpenIntranetProject,
    activateIntranetProject,
    showModalActivateIntranetProject,
    setShowModalActivateIntranetProject,
  } = useCloseRendicontation(projectWrapper);
  const { t } = useTranslation();
  const projectId = projectWrapper?.createUpdateProjectDto?.id;

  if (!initialValues?.values) {
    return <AppLoader />;
  }
  return (
    <>
      <Card className="mt-3">
        <Card.Header className="card-header-border">
          <div className="d-flex justify-content-between">
            <b>{projectWrapper ? t('crud.update-project') : t('crud.create-project')}</b>

            {projectWrapper && (
              <div>
                <LinksButton
                  className="btn-sm btn-links px-2 ms-0 ms-auto"
                  isDisabled={!projectId}
                  onClick={() => setShowModalActivateIntranetProject(true)}
                >
                  <div className="d-flex align-items-center">{t('Activate Intranet Project')}</div>
                </LinksButton>
                <LinksButton
                  className="btn-sm btn-links px-2 ms-0 ms-auto"
                  isDisabled={!projectId}
                  onClick={() => setshowModalOpenIntranetProject(true)}
                >
                  <div className="d-flex align-items-center">{t('Create Intranet Project')}</div>
                </LinksButton>
                <LinksButton
                  className="btn-sm btn-outline-danger px-2 ms-0 ms-auto"
                  isDisabled={!projectId}
                  onClick={() => setshowModalCloseReporting(true)}
                >
                  <div className="d-flex align-items-center">
                    <i className="icon-time-expire-out-of-time pe-2" />
                    {t('project-detail.title-close-reporting')}
                  </div>
                </LinksButton>
              </div>
            )}
          </div>
        </Card.Header>
        <Card.Body>
          <Formik onSubmit={handleSubmit} initialValues={initialValues?.values}>
            {({
              handleSubmit: _handleSubmit,
              handleChange,
              setFieldValue,
              values,
              touched,
              errors,
            }: {
              handleSubmit: () => void;
              handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
              setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
              values: {
                bidOthercosts?: number;
                bidHrcosts?: number;
                bidCosts?: number;
                [key: string]: any;
              };
              touched: { [key: string]: boolean };
              errors: { [key: string]: string };
            }) => {
              const handleChangeCustom = (input: string) => (e: any) => {
                setFieldValue(input, e);
              };

              const onChangeDate = (input: string) => (date: dayjs.Dayjs) => {
                setFieldValue(input, date ? date.format('YYYY-MM-DD') : null);
              };

              const handleChangeCheckbox = (input: string) => (e: any) => {
                setFieldValue(input, e.target.checked);
              };

              const onChangeCosts = (input: string) => (e: any) => {
                const newValue = Number(e.target.value);
                setFieldValue(input, newValue);
                if (input === 'bidHrcosts') {
                  const newBidHr = newValue;
                  const newBidOther = values.bidOthercosts || 0;
                  setFieldValue('bidCosts', newBidHr + newBidOther);
                } else if (input === 'bidOthercosts') {
                  const newBidOther = newValue;
                  const newBidHr = values.bidHrcosts || 0;
                  setFieldValue('bidCosts', newBidHr + newBidOther);
                }
              };

              return (
                <Form onFinish={_handleSubmit}>
                  <Row>
                    <Col lg={3} md={6} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('crud.input-label.status-label')}</label>
                        <Form.Item
                          name={t('crud.input-label.status-label')}
                          initialValue={values.idStato}
                          rules={[{ required: true }]}
                        >
                          <Select
                            showSearch
                            className="w-100"
                            size="large"
                            optionFilterProp="children"
                            filterOption={filterOption}
                            allowClear
                            placeholder={t('crud.input-placeholder.status-placeholder')}
                            onChange={handleChangeCustom('idStato')}
                            value={values.idStato}
                          >
                            {states?.map((el) => (
                              <Select.Option key={`status-${el.id}`} value={el.id}>
                                {StatusBadgeData?.find((state) => state.name === el.value)?.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    <Col lg={3} md={6} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('crud.input-label.type-label')}</label>
                        <Form.Item
                          name={t('crud.input-label.type-label')}
                          initialValue={typologiesData.find((val) => Number(val.id) === values.idType)?.label}
                          rules={[{ required: true }]}
                        >
                          <Select
                            showSearch
                            className="w-100"
                            size="large"
                            allowClear
                            optionFilterProp="children"
                            filterOption={filterOption}
                            placeholder={t('crud.input-placeholder.type-placeholder')}
                            onChange={handleChangeCustom('idType')}
                            value={typologiesData.find((val) => Number(val.id) === values.idType)?.label}
                          >
                            {types?.map((el) => (
                              <Select.Option key={`type-${el.id}`} value={el.id}>
                                {el.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    <Col lg={6} md={12} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('crud.input-label.name-label')}</label>
                        <Form.Item
                          name={t('crud.input-label.name-label')}
                          initialValue={values.name}
                          rules={[{ required: true }]}
                        >
                          <Input
                            type="text"
                            name="name"
                            placeholder={t('crud.input-placeholder.name-placeholder')}
                            value={values.name}
                            onChange={handleChange}
                            size="large"
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={12} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('crud.input-label.description-label')}</label>
                        <Form.Item name={t('crud.input-label.description-label')} initialValue={values.description}>
                          <Input
                            type="text"
                            name="description"
                            placeholder={t('crud.input-placeholder.description-placeholder')}
                            value={values.description}
                            onChange={handleChange}
                            size="large"
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col lg={3} md={6} sm={6} xs={6}>
                      <div className="form-group">
                        <label>{t('crud.input-label.start-date-label')}</label>
                        <Form.Item
                          name={t('crud.input-label.start-date-label')}
                          initialValue={values.startDate ? dayjs(values.startDate) : null}
                          rules={[{ required: true }]}
                        >
                          <DatePicker
                            size="large"
                            className="w-100"
                            format="DD/MM/YYYY"
                            allowClear
                            placeholder={t('crud.input-placeholder.start-date-placeholder')}
                            value={values.startDate ? dayjs(values.startDate) : null}
                            onChange={onChangeDate('startDate')}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col lg={3} md={6} sm={6} xs={6}>
                      <div className="form-group">
                        <label>{t('crud.input-label.end-date-label')}</label>
                        <Form.Item
                          name={t('crud.input-label.end-date-label')}
                          initialValue={values.endDate ? dayjs(values.endDate) : null}
                          rules={[{ required: true }]}
                        >
                          <DatePicker
                            size="large"
                            className="w-100"
                            format="DD/MM/YYYY"
                            placeholder={t('crud.input-placeholder.end-date-placeholder')}
                            value={values.endDate ? dayjs(values.endDate) : null}
                            onChange={onChangeDate('endDate')}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} md={12} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('crud.input-label.project-manager-label')}</label>
                        <Form.Item
                          name={t('crud.input-label.project-manager-label')}
                          initialValue={values.idProjectManager}
                          rules={[{ required: true }]}
                        >
                          <Select
                            showSearch
                            className="w-100"
                            size="large"
                            optionFilterProp="children"
                            filterOption={filterOption}
                            allowClear
                            placeholder={t('crud.input-placeholder.project-manager-placeholder')}
                            onChange={handleChangeCustom('idProjectManager')}
                            value={values.idProjectManager}
                          >
                            {allUsers?.map((el) => (
                              <Select.Option key={`pm-${el.id}`} value={el.id}>
                                {el.nominativo}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    <Col lg={3} md={12} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('crud.input-label.client-label')}</label>
                        <Form.Item
                          name={t('crud.input-label.client-label')}
                          initialValue={values.clientId}
                          rules={[{ required: true }]}
                        >
                          <Select
                            showSearch
                            className="w-100"
                            size="large"
                            optionFilterProp="children"
                            filterOption={filterOption}
                            allowClear
                            placeholder={t('crud.input-placeholder.placeholder-client')}
                            onChange={handleChangeCustom('clientId')}
                            value={values.clientId}
                          >
                            {crudFilters?.clienti?.map((el) => (
                              <Select.Option key={`cl-${el.id}`} value={el.id}>
                                {el.denominazione}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    <Col lg={3} md={12} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('crud.input-label.opportunity-id-label')}</label>
                        <Form.Item
                          name={t('crud.input-label.opportunity-id-label')}
                          initialValue={values.idOpportunity}
                        >
                          <Input
                            type="text"
                            name="idOpportunity"
                            placeholder={t('crud.input-placeholder.placeholder-opportunity-id')}
                            value={values.idOpportunity}
                            onChange={handleChange}
                            size="large"
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col lg={3} md={12} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('crud.input-label.root-opportunity-id-label')}</label>
                        <Form.Item
                          name={t('crud.input-label.root-opportunity-id-label')}
                          initialValue={values.idOpportunityPadre}
                        >
                          <Input
                            type="text"
                            name="idOpportunityPadre"
                            placeholder={t('crud.input-placeholder.placeholder-root-opportunity-id')}
                            value={values.idOpportunityPadre}
                            onChange={handleChange}
                            size="large"
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} md={12} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('crud.input-label.intranet-id-label')}</label>
                        <Form.Item name={t('crud.input-label.intranet-id-label')} initialValue={values.intranetId}>
                          <Input
                            type="text"
                            name="intranetId"
                            placeholder={t('crud.input-placeholder.placeholder-intranet-id')}
                            value={values.intranetId}
                            onChange={handleChange}
                            size="large"
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col lg={3} md={6} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('crud.input-label.business-unit-label')}</label>
                        <Form.Item
                          name={t('crud.input-label.business-unit-label')}
                          initialValue={values.idStrutturaBusiness}
                          rules={[{ required: true }]}
                        >
                          <Select
                            showSearch
                            className="w-100"
                            size="large"
                            optionFilterProp="children"
                            filterOption={filterOption}
                            allowClear
                            placeholder={t('crud.input-placeholder.placeholder-business-unit')}
                            onChange={handleChangeCustom('idStrutturaBusiness')}
                            value={values.idStrutturaBusiness}
                          >
                            {crudFilters?.businessUnit?.map((el) => (
                              <Select.Option key={`bu-${el.id}`} value={el.id}>
                                {el.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    <Col lg={3} md={6} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('crud.input-label.delivery-unit-label')}</label>
                        <Form.Item
                          name={t('crud.input-label.delivery-unit-label')}
                          initialValue={values.idStrutturaDelivery}
                          rules={[{ required: true }]}
                        >
                          <Select
                            showSearch
                            className="w-100"
                            size="large"
                            optionFilterProp="children"
                            filterOption={filterOption}
                            allowClear
                            placeholder={t('crud.input-placeholder.placeholder-delivery-unit')}
                            onChange={handleChangeCustom('idStrutturaDelivery')}
                            value={values.idStrutturaDelivery}
                          >
                            {crudFilters?.deliveryUnit?.map((el) => (
                              <Select.Option key={`du-${el.id}`} value={el.id}>
                                {el.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    <Col lg={3} md={12} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('crud.input-label.company-id-label')}</label>
                        <Form.Item
                          name={t('crud.input-label.company-id-label')}
                          initialValue={values.idAzienda}
                          rules={[{ required: true }]}
                        >
                          <Select
                            showSearch
                            className="w-100"
                            size="large"
                            optionFilterProp="children"
                            filterOption={filterOption}
                            allowClear
                            placeholder={t('crud.input-placeholder.placeholder-company-id')}
                            onChange={handleChangeCustom('idAzienda')}
                            value={values.idAzienda}
                          >
                            {crudFilters?.companyIdList?.map((el) => (
                              <Select.Option key={`cl-${el.id}`} value={el.id}>
                                {el.nome}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} md={12} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('crud.input-label.bid-amount-label')}</label>
                        <Form.Item
                          name={t('crud.input-label.bid-amount-label')}
                          initialValue={values.bidAmount}
                          rules={[{ required: true }]}
                        >
                          <Input
                            type="number"
                            name="bidAmount"
                            placeholder={t('crud.input-placeholder.placeholder-bid-amount')}
                            value={values.bidAmount}
                            onChange={handleChange}
                            size="large"
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col lg={3} md={12} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('crud.input-label.external-quote-id-label')}</label>
                        <Form.Item
                          name={t('crud.input-label.external-quote-id-label')}
                          initialValue={values.externalQuoteId}
                        >
                          <Input
                            type="text"
                            name="externalQuoteId"
                            value={values.externalQuoteId}
                            placeholder={t('crud.input-placeholder.placeholder-external-quote-id')}
                            onChange={handleChange}
                            size="large"
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col lg={3} md={12} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('crud.input-label.contract-id-label')}</label>
                        <Form.Item name={t('crud.input-label.contract-id-label')} initialValue={values.idContratto}>
                          <Input
                            type="text"
                            name="idContratto"
                            value={values.idContratto}
                            onChange={handleChange}
                            placeholder={t('crud.input-placeholder.placeholder-contract-id')}
                            size="large"
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col lg={3} md={12} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('crud.input-label.bid-days-label')}</label>
                        <Form.Item
                          name={t('crud.input-label.bid-days-label')}
                          initialValue={values.bidDays}
                          rules={[{ required: true }]}
                        >
                          <Input
                            type="number"
                            name="bidDays"
                            value={values.bidDays}
                            placeholder={t('crud.input-placeholder.placeholder-bid-days')}
                            onChange={handleChange}
                            size="large"
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} md={12} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('crud.input-label.bid-costs-label')}</label>

                        <Input
                          disabled
                          type="number"
                          name="bidCosts"
                          value={(values.bidHrcosts || 0) + (values.bidOthercosts || 0)}
                          placeholder={t('crud.input-placeholder.placeholder-bid-costs')}
                          onChange={handleChange}
                          size="large"
                        />
                      </div>
                    </Col>
                    <Col lg={3} md={12} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('crud.input-label.bid-hr-costs-label')}</label>
                        <Form.Item
                          name={t('crud.input-label.bid-hr-costs-label')}
                          initialValue={values.bidHrcosts}
                          rules={[{ required: true }]}
                        >
                          <Input
                            type="number"
                            name="bidHrcosts"
                            placeholder={t('crud.input-placeholder.placeholder-bid-hr-costs')}
                            value={values.bidHrcosts}
                            onChange={onChangeCosts('bidHrcosts')}
                            size="large"
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col lg={3} md={12} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('crud.input-label.bid-other-costs-label')}</label>
                        <Form.Item
                          name={t('crud.input-label.bid-other-costs-label')}
                          initialValue={values.bidOthercosts}
                          rules={[{ required: true }]}
                        >
                          <Input
                            type="number"
                            name="bidOthercosts"
                            value={values.bidOthercosts}
                            placeholder={t('crud.input-placeholder.placeholder-bid-other-costs')}
                            onChange={onChangeCosts('bidOthercosts')}
                            size="large"
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col lg={3} md={12} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('crud.input-label.non-workable-amount-label')}</label>
                        <Form.Item
                          name={t('crud.input-label.non-workable-amount-label')}
                          initialValue={values.nonWorkableAmount}
                        >
                          <Input
                            type="number"
                            name="nonWorkableAmount"
                            value={values.nonWorkableAmount}
                            placeholder={t('crud.input-placeholder.placeholder-non-workable-amount')}
                            onChange={onChangeCosts('nonWorkableAmount')}
                            size="large"
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} md={12} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('Revenue Code')}</label>
                        <Form.Item name={t('Revenue Code')} initialValue={values.codiceRicavo}>
                          <Select
                            showSearch
                            className="w-100"
                            size="large"
                            optionFilterProp="children"
                            filterOption={filterOption}
                            allowClear
                            placeholder={t('Select revenue code')}
                            onChange={handleChangeCustom('codiceRicavo')}
                            value={values.codiceRicavo}
                          >
                            {crudFilters?.codiciRicavo?.map((el) => (
                              <Select.Option key={`cr-${el.id}`} value={el.id}>
                                {el.codice}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    <Col lg={3} md={12} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('Cost Code')}</label>
                        <Form.Item name={t('Cost Code')} initialValue={values.codiceCosto}>
                          <Select
                            showSearch
                            className="w-100"
                            size="large"
                            optionFilterProp="children"
                            filterOption={filterOption}
                            allowClear
                            placeholder={t('Select Cost Code')}
                            onChange={handleChangeCustom('codiceCosto')}
                            value={values.codiceCosto}
                          >
                            {crudFilters?.codiciCosto?.map((el) => (
                              <Select.Option key={`cc-${el.id}`} value={el.id}>
                                {el.codice}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} md={12} sm={12} xs={12}>
                      <div className="form-group">
                        <FormBootstrap.Check
                          type={'checkbox'}
                          onChange={handleChangeCheckbox('isPrjCoordinamentoFrom')}
                          checked={values.isPrjCoordinamentoFrom}
                          label={`Is from coordination`}
                        />
                      </div>
                    </Col>
                    <Col lg={3} md={12} sm={12} xs={12}>
                      <div className="form-group">
                        <FormBootstrap.Check
                          type={'checkbox'}
                          onChange={handleChangeCheckbox('isPrjCoordinamentoTo')}
                          checked={values.isPrjCoordinamentoTo}
                          label={`Is to coordination`}
                        />
                      </div>
                    </Col>
                    <Col lg={3} md={12} sm={12} xs={12}>
                      <div className="form-group">
                        <FormBootstrap.Check
                          type={'checkbox'}
                          onChange={handleChangeCheckbox('isDeleted')}
                          checked={values.isDeleted}
                          label={`Is deleted`}
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-end">
                    <LinksButton type="submit" className="btn-primary btn-links">
                      {projectWrapper ? t('crud.button-label.update') : t('crud.button-label.create')}
                    </LinksButton>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Card.Body>
      </Card>

      <DelegateManagement
        type={DelegateTypesEnum.PROJECT}
        idProject={projectId}
        idProjectManager={projectWrapper?.createUpdateProjectDto?.idProjectManager}
      />
      <ModalAction
        title={t('project-detail.title-close-reporting')}
        actionLabel={t('common.confirm')}
        cancelLabel={t('common.cancel')}
        action={() => {
          closeReporting();
        }}
        setShow={(value) => setshowModalCloseReporting(value)}
        show={showModalCloseReporting}
      >
        <span className="text-danger">
          <i className="fa-solid fa-triangle-exclamation"></i> <b>{t('common.attention')}</b>{' '}
        </span>
        <div className="p-1">
          <p>{t('project-detail.content-close-reporting')}</p>
        </div>
      </ModalAction>
      <ModalAction
        title={t('Create Intranet Project')}
        actionLabel={t('common.confirm')}
        cancelLabel={t('common.cancel')}
        action={() => {
          createIntranetProject();
        }}
        setShow={(value) => setshowModalOpenIntranetProject(value)}
        show={showModalOpenIntranetProject}
      >
        <span className="text-danger">
          <i className="fa-solid fa-triangle-exclamation"></i> <b>{t('common.attention')}</b>{' '}
        </span>
        <div className="p-1">
          <p>{t('Are you sure you want to continue?')}</p>
        </div>
      </ModalAction>
      <ModalAction
        title={t('Activate Intranet Project')}
        actionLabel={t('common.confirm')}
        cancelLabel={t('common.cancel')}
        action={() => {
          activateIntranetProject();
        }}
        setShow={(value) => setShowModalActivateIntranetProject(value)}
        show={showModalActivateIntranetProject}
      >
        <span className="text-danger">
          <i className="fa-solid fa-triangle-exclamation"></i> <b>{t('common.attention')}</b>{' '}
        </span>
        <div className="p-1">
          <p>{t('Are you sure you want to continue?')}</p>
        </div>
      </ModalAction>
    </>
  );
};

export default ProjectCrud;
