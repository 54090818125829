/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * @export
 */
export const FilterDtoStatoEnum = {
    Pending: 'PENDING',
    Planning: 'PLANNING',
    ClosedLost: 'CLOSED_LOST',
    IntranetPending: 'INTRANET_PENDING',
    WorkInProgress: 'WORK_IN_PROGRESS',
    Completed: 'COMPLETED',
    ClosedWonPlanning: 'CLOSED_WON_PLANNING',
    PlanningOk: 'PLANNING_OK',
    ControlloCdg: 'CONTROLLO_CDG',
    ControlloCl: 'CONTROLLO_CL',
    ReportingClosed: 'REPORTING_CLOSED',
    EconomicsClosed: 'ECONOMICS_CLOSED'
};
/**
 * @export
 */
export const FilterDtoTipologiaEnum = {
    Corpo: 'CORPO',
    Canone: 'CANONE',
    Costo: 'COSTO',
    Consumo: 'CONSUMO',
    Contributo: 'CONTRIBUTO',
    Assets: 'ASSETS'
};
/**
 * Check if a given object implements the FilterDto interface.
 */
export function instanceOfFilterDto(value) {
    return true;
}
export function FilterDtoFromJSON(json) {
    return FilterDtoFromJSONTyped(json, false);
}
export function FilterDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'fullText': json['fullText'] == null ? undefined : json['fullText'],
        'idProgetto': json['idProgetto'] == null ? undefined : json['idProgetto'],
        'inizio': json['inizio'] == null ? undefined : (new Date(json['inizio'])),
        'fine': json['fine'] == null ? undefined : (new Date(json['fine'])),
        'nomeProgetto': json['nomeProgetto'] == null ? undefined : json['nomeProgetto'],
        'stato': json['stato'] == null ? undefined : json['stato'],
        'nomeCliente': json['nomeCliente'] == null ? undefined : json['nomeCliente'],
        'businessManager': json['businessManager'] == null ? undefined : json['businessManager'],
        'projectManager': json['projectManager'] == null ? undefined : json['projectManager'],
        'tipologia': json['tipologia'] == null ? undefined : json['tipologia'],
        'prezzoDa': json['prezzoDa'] == null ? undefined : json['prezzoDa'],
        'prezzoA': json['prezzoA'] == null ? undefined : json['prezzoA'],
        'flagPlaceholderPlanned': json['flagPlaceholderPlanned'] == null ? undefined : json['flagPlaceholderPlanned'],
        'strutturaDelivery': json['strutturaDelivery'] == null ? undefined : json['strutturaDelivery'],
        'quoteId': json['quoteId'] == null ? undefined : json['quoteId'],
        'opportunityId': json['opportunityId'] == null ? undefined : json['opportunityId'],
    };
}
export function FilterDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'fullText': value['fullText'],
        'idProgetto': value['idProgetto'],
        'inizio': value['inizio'] == null ? undefined : ((value['inizio']).toISOString()),
        'fine': value['fine'] == null ? undefined : ((value['fine']).toISOString()),
        'nomeProgetto': value['nomeProgetto'],
        'stato': value['stato'],
        'nomeCliente': value['nomeCliente'],
        'businessManager': value['businessManager'],
        'projectManager': value['projectManager'],
        'tipologia': value['tipologia'],
        'prezzoDa': value['prezzoDa'],
        'prezzoA': value['prezzoA'],
        'flagPlaceholderPlanned': value['flagPlaceholderPlanned'],
        'strutturaDelivery': value['strutturaDelivery'],
        'quoteId': value['quoteId'],
        'opportunityId': value['opportunityId'],
    };
}
