import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../../../core/store/hooks';
import {
  FatturaEmessaSearchDto,
  FatturaRicevutaSearchDto,
  SearchFattureEmesseRequest,
  SearchFattureRicevuteRequest,
} from '@api/client';
import { StorageKeysEnum } from '../../../../../enums/storageKeys.enum';
import {
  getFattureEmesse,
  getFattureRicevute,
  saveFattureEmesseFiltersUsed,
  saveFattureRicevuteFiltersUsed,
} from '../../../../../pages/private/active-cycle/_redux/actions';

const useFormFatture = (initialData: any, billType: 'active' | 'passive') => {
  const dispatch = useAppDispatch();
  const isSubmitting = useRef(false);
  const [chipsFormData, setChipsFormData] = useState<any[]>([]);
  const [filterParams, setFilterParams] = useState<any[]>([]);

  const { reset, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: initialData,
  });

  const onSubmit = (data: any, page?: number) => {
    sessionStorage.setItem(StorageKeysEnum.CURRENT_PAGE_ACTIVE_CYCLE_BILLS, String(0));
    isSubmitting.current = true;
    const formDataArray = [];

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (data[key] !== initialData[key]) {
          formDataArray.push([key, data[key]]);
        }
      }
    }

    setFilterParams([...formDataArray]);
    setChipsFormData([...formDataArray]);

    switch (billType) {
      case 'active':
        const filtersBillsActive: FatturaEmessaSearchDto = {};

        if (data.idFattura) {
          filtersBillsActive.idFattura = data.idFattura;
        }
        if (data.numeroOrdine) {
          filtersBillsActive.numeroOrdine = data.numeroOrdine;
        }
        if (data.startEndRange && data.startEndRange.length > 0) {
          filtersBillsActive.dataFatturaDa = new Date(data.startEndRange[0]);
          filtersBillsActive.dataFatturaA = new Date(data.startEndRange[1]);
        }

        filtersBillsActive.excludefattureAssociate = data.excludefattureAssociate;

        filtersBillsActive.paging = {
          page: 0,
          size: 10,
        };
        const payloadActive: SearchFattureEmesseRequest = {
          fatturaEmessaSearchDto: filtersBillsActive,
        };
        dispatch(saveFattureEmesseFiltersUsed(filtersBillsActive));
        dispatch(getFattureEmesse(payloadActive));
        break;
      case 'passive':
        const filtersBillsPassive: FatturaRicevutaSearchDto = {};

        if (data.idFattura) {
          filtersBillsPassive.fkFatturaRicevuta = data.idFattura;
        }
        if (data.numeroOrdine) {
          filtersBillsPassive.numeroOrdine = data.numeroOrdine;
        }
        if (data.startEndRange && data.startEndRange.length > 0) {
          filtersBillsPassive.dataFatturaDa = new Date(data.startEndRange[0]);
          filtersBillsPassive.dataFatturaA = new Date(data.startEndRange[1]);
        }
        if (data.supplier) {
          filtersBillsPassive.fkFornitore = data.supplier;
        }
        if (data.legalEntity) {
          filtersBillsPassive.fkAzienda = data.legalEntity;
        }

        filtersBillsPassive.excludefattureAssociate = data.excludefattureAssociate;

        filtersBillsPassive.paging = {
          page: 0,
          size: 10,
        };
        const payloadPassive: SearchFattureRicevuteRequest = {
          fatturaRicevutaSearchDto: filtersBillsPassive,
        };
        dispatch(saveFattureRicevuteFiltersUsed(filtersBillsPassive));
        dispatch(getFattureRicevute(payloadPassive));
        break;
      default:
        break;
    }

    handleSubmit(data);
  };

  const onReset = () => {
    reset(initialData);
    setChipsFormData([]);
    switch (billType) {
      case 'active':
        dispatch(saveFattureEmesseFiltersUsed(initialData));
        break;
      case 'passive':
        dispatch(saveFattureRicevuteFiltersUsed(initialData));
        break;
      default:
        break;
    }
  };

  return { onSubmit, onReset, filterParams, chipsFormData, setChipsFormData, reset };
};

export default useFormFatture;
