import { Table } from 'react-bootstrap';
import { Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PathEnum } from '../../../../core/router/path.enum';
import { getProjectDetails } from '../../../../pages/private/project-list/_redux/actions';
import { useAppDispatch } from '../../../../core/store/hooks';
import { ProjectLabels } from '../../../../enums/project.enum';
import { useTranslation } from 'react-i18next';
import { crudGetProject } from '../../../../pages/private/crud/_redux/actions';
import AuthService from '../../../../core/auth/auth.service';
import StatusBadge from '../../../../shared/design-system/components/project-status-badge';
import BadgeSize from '../../../../shared/design-system/components/project-status-badge/enum-size';
import './styles.scss';

export interface TableProps {
  data: Array<any>;
}

export interface DataTable {
  [key: string]: {
    id: number;
    label: string;
    value: string | number | boolean;
    visible: boolean;
    icon?: string;
    statusBadge?: string;
    className?: string;
    isButton?: boolean;
    tooltip?: string;
    isSelect?: boolean;
    options?: Array<any>;
    width?: string;
  };
}

const ProjectListTable: React.FC<TableProps> = ({ data }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleActionClick = (id: any) => {
    if (!id) return;
    dispatch(getProjectDetails(id))
      .unwrap()
      .then((res) => {
        if (res) navigate(`/${PathEnum.PRIVATE}/${PathEnum.PROGETTI}/${id}`);
      });
  };

  const handleEditClick = (id: number, editDeliveryEnabled: boolean) => {
    if (!id) return;
    if (AuthService.isSuperAdmin()) {
      dispatch(crudGetProject(id))
        .unwrap()
        .then((res) => {
          if (res) navigate(`/${PathEnum.PRIVATE}/${PathEnum.CRUD}`);
        });
    }
    if (!AuthService.isSuperAdmin() && editDeliveryEnabled) {
      dispatch(getProjectDetails(id))
        .unwrap()
        .then((res) => {
          if (res) navigate(`/${PathEnum.PRIVATE}/${PathEnum.CRUD}`);
        });
    }
  };

  if (data && data.length > 0) {
    const header = Object.keys(data[0]);
    return (
      <div className="table-responsive table-bordered">
        <Table className={'mt-0 mb-0'}>
          <thead className="bg-red">
            <tr>
              {header.map((el) => {
                if (data[0][el].visible) {
                  return (
                    <th className={`${data[0][el].className} align-middle`} key={el} scope="col">
                      <span>{data[0][el].label}</span>
                    </th>
                  );
                }
              })}
            </tr>
          </thead>
          <tbody>
            {data.map((e: DataTable, index: number) => (
              <tr key={'row-' + index}>
                {header.map((h) => {
                  if (data[0][h].visible) {
                    return (
                      <td
                        className={`${data[0][h].className} ${
                          e[h].label === ProjectLabels.DATE_LAST_MODIFY && 'text-center'
                        }`}
                        key={`${h}-${index} `}
                      >
                        {' '}
                        {e[h].isButton && (
                          <div className="d-flex align-items-center justify-content-center">
                            {(AuthService.isSuperAdmin() || e.editDeliveryEnabled.value) && (
                              <Tooltip placement="top" color="white" title="Edit">
                                <button
                                  onClick={() => {
                                    handleEditClick(e?.id.value as number, e.editDeliveryEnabled.value as boolean);
                                  }}
                                  className="btn-icon"
                                >
                                  <i className={'icon-edit text-blue-links me-1'}></i>
                                </button>
                              </Tooltip>
                            )}
                            <Tooltip placement="top" color="white" title={e[h]?.tooltip}>
                              <button
                                onClick={() => {
                                  handleActionClick(e?.id.value);
                                }}
                                className="btn-icon"
                              >
                                <i className={e[h].icon + ' text-blue-links me-1'}></i>
                              </button>
                            </Tooltip>
                          </div>
                        )}
                        <span className="d-inline-flex">
                          {e[h].statusBadge && (
                            <StatusBadge className="me-2" size={BadgeSize.LARGE} pill>
                              {e[h].statusBadge}
                            </StatusBadge>
                          )}
                          <p className="my-auto">{e[h].value ?? ''}</p>
                        </span>
                      </td>
                    );
                  }
                })}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  } else {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '4rem' }}>
        {t('project-list.no-projects')}
      </div>
    );
  }
};

export default ProjectListTable;
