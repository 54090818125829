import {
  AssociaFatturaProgettoRequest,
  AssociaFattureProgettoDtoList,
  Configuration,
  ContractOrderOutputDto,
  ContractOrderOutputListDto,
  CreateCicloAttivoRequest,
  FatturaEmessaDto,
  FatturaEmessaPagedDto,
  FatturaEmessaSearchDto,
  FatturaRicevutaPagedDto,
  FatturaRicevutaSearchDto,
  FattureProgettoControllerApi,
  ProjectLightInfoDto,
  SearchContractOrdersRequest,
  SearchFattureEmesseRequest,
  SearchFattureRicevuteRequest,
} from '@api/client';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import getConfiguration, { errorHandler } from '../../../../core/store/config';
import { ThunkApiConfig } from '../../../../core/store';

enum FattureActions {
  fattureGetFattureEmesse = '[Fatture] Get Fatture Emesse',
  fattureGetFattureRicevute = '[Fatture] Get Fatture Ricevute',
  associateFatturaProject = '[Fatture] Associate Fattura Project',
  saveFattureEmesseFiltersUsed = '[Fatture] Save Fatture Emesse Filters Used',
  saveFattureRicevuteFiltersUsed = '[Fatture] Save Fatture Ricevute Filters Used',
  getOrdiniAttivi = '[Fatture] Get Ordini Attivi',
  createOrdineAttivo = '[Fatture] Create Ordine Attivo',
  getProjectsForActiveBills = '[Fatture] Get Projects For Active Bills',
  getProjectsForPassiveBills = '[Fatture] Get Projects For Passive Bills',
}

export const getFattureEmesse = createAsyncThunk<
  FatturaEmessaPagedDto | undefined,
  SearchFattureEmesseRequest,
  ThunkApiConfig
>(FattureActions.fattureGetFattureEmesse, async (payload, { dispatch, extra, getState }) => {
  try {
    return await new FattureProgettoControllerApi(getConfiguration(Configuration, extra, getState)).searchFattureEmesse(
      payload
    );
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const getFattureRicevute = createAsyncThunk<
  FatturaRicevutaPagedDto | undefined,
  SearchFattureRicevuteRequest,
  ThunkApiConfig
>(FattureActions.fattureGetFattureRicevute, async (payload, { dispatch, extra, getState }) => {
  try {
    return await new FattureProgettoControllerApi(
      getConfiguration(Configuration, extra, getState)
    ).searchFattureRicevute(payload);
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const associateFatturaProject = createAsyncThunk<
  AssociaFattureProgettoDtoList | undefined,
  AssociaFatturaProgettoRequest,
  ThunkApiConfig
>(FattureActions.associateFatturaProject, async (payload, { dispatch, extra, getState }) => {
  try {
    return await new FattureProgettoControllerApi(
      getConfiguration(Configuration, extra, getState)
    ).associaFatturaProgetto(payload);
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const getOrdiniAttivi = createAsyncThunk<ContractOrderOutputListDto | undefined, void, ThunkApiConfig>(
  FattureActions.getOrdiniAttivi,
  async (_, { dispatch, extra, getState }) => {
    const payload: SearchContractOrdersRequest = {
      contractOrderSearchDto: {},
    };
    try {
      return await new FattureProgettoControllerApi(
        getConfiguration(Configuration, extra, getState)
      ).searchContractOrders(payload);
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const getProjectsForActiveBills = createAsyncThunk<ProjectLightInfoDto[] | undefined, void, ThunkApiConfig>(
  FattureActions.getProjectsForActiveBills,
  async (_, { dispatch, extra, getState }) => {
    try {
      return await new FattureProgettoControllerApi(
        getConfiguration(Configuration, extra, getState)
      ).getProjectLightInfoFattureAttive();
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const getProjectsForPassiveBills = createAsyncThunk<ProjectLightInfoDto[] | undefined, void, ThunkApiConfig>(
  FattureActions.getProjectsForPassiveBills,
  async (_, { dispatch, extra, getState }) => {
    try {
      return await new FattureProgettoControllerApi(
        getConfiguration(Configuration, extra, getState)
      ).getProjectLightInfoFatturePassive();
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const createOrdineAttivo = createAsyncThunk<
  ContractOrderOutputDto | undefined,
  CreateCicloAttivoRequest,
  ThunkApiConfig
>(FattureActions.createOrdineAttivo, async (payload, { dispatch, extra, getState }) => {
  try {
    return await new FattureProgettoControllerApi(getConfiguration(Configuration, extra, getState)).createCicloAttivo(
      payload
    );
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const saveFattureEmesseFiltersUsed = createAction<FatturaEmessaSearchDto>(
  FattureActions.saveFattureEmesseFiltersUsed
);

export const saveFattureRicevuteFiltersUsed = createAction<FatturaRicevutaSearchDto>(
  FattureActions.saveFattureRicevuteFiltersUsed
);
