import { useEffect } from 'react';
import { RevenueAllocation, RevenueEnum } from '../../_models/revenueAllocation';

const useRevenueProgressValidation = (
  revenueAllocations: RevenueAllocation[] | null,
  setRevenueProgressValidation: React.Dispatch<React.SetStateAction<boolean>>
) => {
  useEffect(() => {
    if (revenueAllocations) {
      let validation = true;
      revenueAllocations.forEach((el) => {
        if (el.revenueEnum === RevenueEnum.RevenuePerMonthPercentage) {
          const sum = el.revenueList.reduce((acc, curr) => acc + (curr.amount ?? 0), 0);
          if (sum >= 100.01) {
            validation = false;
          }
        }
      });

      setRevenueProgressValidation(validation);
    }
  }, [revenueAllocations]);
};

export default useRevenueProgressValidation;
