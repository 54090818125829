/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MeseAnnoLightFromJSON, MeseAnnoLightToJSON, } from './MeseAnnoLight';
import { ProjectOrganizationDtoFromJSON, ProjectOrganizationDtoToJSON, } from './ProjectOrganizationDto';
import { ProjectUserDtoFromJSON, ProjectUserDtoToJSON, } from './ProjectUserDto';
/**
 * @export
 */
export const FilterProjectMonthValuesDtoStatiEnum = {
    Pending: 'PENDING',
    Planning: 'PLANNING',
    ClosedLost: 'CLOSED_LOST',
    IntranetPending: 'INTRANET_PENDING',
    WorkInProgress: 'WORK_IN_PROGRESS',
    Completed: 'COMPLETED',
    ClosedWonPlanning: 'CLOSED_WON_PLANNING',
    PlanningOk: 'PLANNING_OK',
    ControlloCdg: 'CONTROLLO_CDG',
    ControlloCl: 'CONTROLLO_CL',
    ReportingClosed: 'REPORTING_CLOSED',
    EconomicsClosed: 'ECONOMICS_CLOSED'
};
/**
 * @export
 */
export const FilterProjectMonthValuesDtoProjectTypeEnum = {
    Corpo: 'CORPO',
    Canone: 'CANONE',
    Costo: 'COSTO',
    Consumo: 'CONSUMO',
    Contributo: 'CONTRIBUTO',
    Assets: 'ASSETS'
};
/**
 * Check if a given object implements the FilterProjectMonthValuesDto interface.
 */
export function instanceOfFilterProjectMonthValuesDto(value) {
    return true;
}
export function FilterProjectMonthValuesDtoFromJSON(json) {
    return FilterProjectMonthValuesDtoFromJSONTyped(json, false);
}
export function FilterProjectMonthValuesDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'stati': json['stati'] == null ? undefined : json['stati'],
        'clientLeads': json['clientLeads'] == null ? undefined : (json['clientLeads'].map(ProjectUserDtoFromJSON)),
        'projectLeads': json['projectLeads'] == null ? undefined : (json['projectLeads'].map(ProjectUserDtoFromJSON)),
        'businessUnit': json['businessUnit'] == null ? undefined : (json['businessUnit'].map(ProjectOrganizationDtoFromJSON)),
        'deliveryUnit': json['deliveryUnit'] == null ? undefined : (json['deliveryUnit'].map(ProjectOrganizationDtoFromJSON)),
        'projectType': json['projectType'] == null ? undefined : json['projectType'],
        'meseAnnoDaConsolidare': json['meseAnnoDaConsolidare'] == null ? undefined : MeseAnnoLightFromJSON(json['meseAnnoDaConsolidare']),
    };
}
export function FilterProjectMonthValuesDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'stati': value['stati'],
        'clientLeads': value['clientLeads'] == null ? undefined : (value['clientLeads'].map(ProjectUserDtoToJSON)),
        'projectLeads': value['projectLeads'] == null ? undefined : (value['projectLeads'].map(ProjectUserDtoToJSON)),
        'businessUnit': value['businessUnit'] == null ? undefined : (value['businessUnit'].map(ProjectOrganizationDtoToJSON)),
        'deliveryUnit': value['deliveryUnit'] == null ? undefined : (value['deliveryUnit'].map(ProjectOrganizationDtoToJSON)),
        'projectType': value['projectType'],
        'meseAnnoDaConsolidare': MeseAnnoLightToJSON(value['meseAnnoDaConsolidare']),
    };
}
