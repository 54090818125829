import { Dispatch, SetStateAction } from 'react';
import { FieldEnum } from './useGetAndMapEmittedBills';

const useHandleChangeActiveCycleAssociation = (setEmittedBills: Dispatch<SetStateAction<any[]>>) => {
  const handleChangeValue = (value: any, id: number, indexBill: number) => {
    if (id === FieldEnum.ORDER) {
      setEmittedBills((prev) => {
        return prev.map((el, i) => {
          if (i === indexBill) {
            return {
              ...el,
              numeroOrdine: {
                ...el.numeroOrdine,
                value,
              },
            };
          }
          return el;
        });
      });
    }
    if (id === FieldEnum.PROJECT) {
      setEmittedBills((prev) => {
        return prev.map((el, i) => {
          if (i === indexBill) {
            return {
              ...el,
              progetti: {
                ...el.progetti,
                value,
              },
            };
          }
          return el;
        });
      });
    }
  };

  return { handleChangeValue };
};

export default useHandleChangeActiveCycleAssociation;
