/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ExternalCostDtoFromJSON, ExternalCostDtoToJSON, } from './ExternalCostDto';
import { ProjectOrderDtoFromJSON, ProjectOrderDtoToJSON, } from './ProjectOrderDto';
/**
 * Check if a given object implements the AssociazioniFattureOrdiniDto interface.
 */
export function instanceOfAssociazioniFattureOrdiniDto(value) {
    return true;
}
export function AssociazioniFattureOrdiniDtoFromJSON(json) {
    return AssociazioniFattureOrdiniDtoFromJSONTyped(json, false);
}
export function AssociazioniFattureOrdiniDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'orderId': json['orderId'] == null ? undefined : json['orderId'],
        'orderExternalId': json['orderExternalId'] == null ? undefined : json['orderExternalId'],
        'orderName': json['orderName'] == null ? undefined : json['orderName'],
        'contractValue': json['contractValue'] == null ? undefined : json['contractValue'],
        'associatedProjects': json['associatedProjects'] == null ? undefined : (json['associatedProjects'].map(ProjectOrderDtoFromJSON)),
        'externalCosts': json['externalCosts'] == null ? undefined : (json['externalCosts'].map(ExternalCostDtoFromJSON)),
    };
}
export function AssociazioniFattureOrdiniDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'orderId': value['orderId'],
        'orderExternalId': value['orderExternalId'],
        'orderName': value['orderName'],
        'contractValue': value['contractValue'],
        'associatedProjects': value['associatedProjects'] == null ? undefined : (value['associatedProjects'].map(ProjectOrderDtoToJSON)),
        'externalCosts': value['externalCosts'] == null ? undefined : (value['externalCosts'].map(ExternalCostDtoToJSON)),
    };
}
