export enum StorageKeysEnum {
  TOKEN = 'topstar-token',
  USER = 'topstar-user',
  IS_HCC = 'canDefineDesignatedUserForHimself',
  CURRENT_PAGE_PROJECT_LIST = 'current-page-project-list',
  CURRENT_PAGE_MANAGEMENT_TOOLS = 'current-page-management-tools',
  CURRENT_PAGE_DASHBOARD_PROJECTS = 'current-page-dashboard-projects',
  CURRENT_PAGE_DASHBOARD_USERS = 'current-page-dashboard-users',
  CURRENT_PAGE_DASHBOARD_UNDERBOOKING = 'current-page-dashboard-underbooking',
  CURRENT_PAGE_DASHBOARD_OVERBOOKING = 'current-page-dashboard-overbooking',
  CURRENT_PAGE_ORDERS = 'current-page-orders',
  CURRENT_PAGE_SUPPLIERS = 'current-page-suppliers',
  CURRENT_PAGE_ACTIVE_CYCLE_BILLS = 'current-page-active-cycle-bills',
  CURRENT_PAGE_PASSIVE_CYCLE_BILLS = 'current-page-passive-cycle-bills',
}
