/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the FatturaDettaglioDto interface.
 */
export function instanceOfFatturaDettaglioDto(value) {
    return true;
}
export function FatturaDettaglioDtoFromJSON(json) {
    return FatturaDettaglioDtoFromJSONTyped(json, false);
}
export function FatturaDettaglioDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'idAssociazione': json['idAssociazione'] == null ? undefined : json['idAssociazione'],
        'idProject': json['idProject'] == null ? undefined : json['idProject'],
        'idCostType': json['idCostType'] == null ? undefined : json['idCostType'],
        'idGroup': json['idGroup'] == null ? undefined : json['idGroup'],
        'idOrdine': json['idOrdine'] == null ? undefined : json['idOrdine'],
        'amount': json['amount'] == null ? undefined : json['amount'],
    };
}
export function FatturaDettaglioDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'idAssociazione': value['idAssociazione'],
        'idProject': value['idProject'],
        'idCostType': value['idCostType'],
        'idGroup': value['idGroup'],
        'idOrdine': value['idOrdine'],
        'amount': value['amount'],
    };
}
