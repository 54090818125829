import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../core/store/hooks';
import { fattureEmesseSelector } from '../_redux/selectors';
import { ContractOrderOutputDto, FatturaEmessaDto, ProjectLightInfoDto } from '@api/client';
import { TableProps } from '../../project-list/project-list-table';
import { format } from 'date-fns';
import { formatCurrency } from '../../../../shared/utils/common.utils';

export enum FieldEnum {
  ORDER,
  PROJECT,
}

const useGetAndMapEmittedBills = (
  activeOrders: ContractOrderOutputDto[],
  projectsForActiveBills: ProjectLightInfoDto[]
) => {
  const { t } = useTranslation();
  const [emittedBills, setEmittedBills] = useState<TableProps['data']>([]);

  const filteredBills = useAppSelector(fattureEmesseSelector);

  useEffect(() => {
    setEmittedBills(mapBills(filteredBills?.content || []));
  }, [filteredBills]);

  const mapBills = (bills: FatturaEmessaDto[]): TableProps['data'] => {
    if (!bills) return [];
    return bills?.map((bill: FatturaEmessaDto) => {
      return {
        id: { label: t('ID'), value: bill.idFattura, visible: true },
        cliente: {
          label: t('Account'),
          value: bill.cliente,
          visible: true,
        },
        dataFattura: {
          label: t('Date'),
          value: bill.dataFattura ? format(new Date(bill.dataFattura), 'dd/MM/yyyy') : '-',
          visible: true,
        },
        imponibile: {
          label: t('Amount'),
          value: `${formatCurrency(bill.importo)}€`,
          visible: true,
          className: 'text-end',
        },
        denominazioneAzienda: { label: t('Legal entity'), value: bill.denominazioneAzienda, visible: true },
        numeroOrdine: {
          id: FieldEnum.ORDER,
          label: t('Order number'),
          value: bill.associatedOrders?.[0]?.orderExternalId,
          visible: true,
          isSelect: true,
          options: activeOrders,
          tooltip: t('Select order'),
          width: '200px',
        },
        progetti: {
          id: FieldEnum.PROJECT,
          label: t('Projects'),
          value: projectsForActiveBills.find(
            (el) => el.projectId === bill.associatedOrders?.[0]?.associatedProjects?.[0]?.projectId
          )?.projectName,
          visible: true,
          isSelect: true,
          options: projectsForActiveBills,
          tooltip: t('Select project'),
          width: '200px',
        },
        azione: {
          label: t('project-list.action'),
          value: '',
          visible: true,
          isButton: true,
          icon: 'icon-save',
          className: 'text-center',
          tooltip: t('Associate'),
        },
      };
    });
  };
  return { emittedBills, setEmittedBills, filteredBills };
};

export default useGetAndMapEmittedBills;
