import { getFormattedDateString, getMonthsToDelete } from '../../../../../shared/utils/common.utils';
import { DeleteMonthRequest, StaffExtendedDto } from '@api/client';
import { useState } from 'react';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { CheckboxProps } from 'antd';
import { useAppDispatch } from '../../../../../core/store/hooks';
import { deleteMonths } from '../_redux/actions';
import { MonthOption } from '../_models/monthOption';

const useSpreadOutDays = (
  fullAllocations: Partial<StaffExtendedDto>[] | null | undefined,
  currentYear: number | 'all',
  totalGUPlanned: number[],
  idProject: number | undefined,
  setFullAllocations: React.Dispatch<React.SetStateAction<Partial<StaffExtendedDto>[] | null | undefined>>,
  filterAllocationsByYear: (year: number | 'all', allocations: Partial<StaffExtendedDto>[] | null | undefined) => void
) => {
  const dispatch = useAppDispatch();
  const monthOptionsObj: MonthOption[] =
    fullAllocations?.[0]?.allocazioni
      ?.filter((alloc) => alloc.editEnable)
      ?.map((el) => ({
        label: el.month,
        value: getFormattedDateString(el.month, el.year),
      })) ?? [];

  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(monthOptionsObj.map((el) => el.value));

  const onChangeCheckedList = (list: any[]) => {
    const lastToggledItem =
      list.find((item) => !checkedList.includes(item)) || checkedList.find((item) => !list.includes(item));
    if (typeof lastToggledItem === 'number') {
      const year = lastToggledItem;
      const isSelectingYear = !checkedList.includes(year);
      if (isSelectingYear) {
        const monthsOfYear = monthOptionsObj
          .filter((option) => option.value.includes(year.toString()))
          .map((option) => option.value);
        setCheckedList([...checkedList, ...monthsOfYear, year]);
      } else {
        const monthsOfYear = monthOptionsObj
          .filter((option) => option.value.includes(year.toString()))
          .map((option) => option.value);
        setCheckedList(checkedList.filter((item) => !monthsOfYear.includes(item.toString()) && item !== year));
      }
    } else {
      setCheckedList(list);
    }
  };

  const onCheckAllChange: CheckboxProps['onChange'] = (e) => {
    const monthOptions = monthOptionsObj.map((el) => el.value);
    setCheckedList(e.target.checked ? monthOptions : []);
    if (typeof e.target.value === 'number') {
      setCheckedList(monthOptions.map((el) => el.toString().includes(e.target.value)));
    }
  };

  const clearForecastDays = (index: number) => {
    let newFullAllocations = fullAllocations?.map((el) => {
      if (el.allocazioni?.[0].id == fullAllocations?.[index].allocazioni?.[0].id) {
        return {
          ...el,
          allocazioni: el.allocazioni?.map((el) => {
            if (el.editEnable) {
              return {
                ...el,
                actualAllocation: 0,
              };
            }
            return el;
          }),
        };
      }
      return el;
    });

    setFullAllocations(newFullAllocations);
    filterAllocationsByYear(currentYear, newFullAllocations);
  };

  const clearForecastDaysFromID = (userId: string) => {
    let newFullAllocations = fullAllocations?.map((el) => {
      if (el.userId === userId) {
        return {
          ...el,
          allocazioni: el.allocazioni?.map((el) => {
            if (el.editEnable) {
              return {
                ...el,
                actualAllocation: 0,
              };
            }
            return el;
          }),
        };
      }
      return el;
    });

    setFullAllocations(newFullAllocations);
    filterAllocationsByYear(currentYear, newFullAllocations);
  };

  const spalmaGiorni = (index: number) => {
    const totGiorni = totalGUPlanned?.[index] ?? 0;
    let newFullAllocations = fullAllocations?.map((el) => {
      if (el.allocazioni?.[0].id === fullAllocations?.[index].allocazioni?.[0].id) {
        return {
          ...el,
          allocazioni: el.allocazioni?.map((el) => {
            if (checkedList.includes(getFormattedDateString(el.month, el.year))) {
              const checkedListLenght = checkedList.filter((item, index) => {
                if (typeof item === 'string') {
                  return checkedList.indexOf(item) === index;
                }
                return false;
              }).length;
              return {
                ...el,
                actualAllocation: Number((totGiorni / checkedListLenght).toFixed(2)),
              };
            }
            if (el.editEnable) {
              return {
                ...el,
                actualAllocation: 0,
              };
            }
            return el;
          }),
        };
      }
      return el;
    });
    setFullAllocations(newFullAllocations);
    filterAllocationsByYear(currentYear, newFullAllocations);
  };

  const removeMonths = () => {
    let newFullAllocations = fullAllocations?.map((el) => {
      return {
        ...el,
        allocazioni: el.allocazioni?.filter((el) => !checkedList.includes(getFormattedDateString(el.month, el.year))),
      };
    });
    setFullAllocations(newFullAllocations);
    filterAllocationsByYear(currentYear, newFullAllocations);

    if (idProject) {
      const monthsYears = getMonthsToDelete(checkedList);
      const payload: DeleteMonthRequest = {
        deleteMonthsRequestDto: {
          idProject,
          monthsYears,
        },
      };
      dispatch(deleteMonths(payload));
    }
  };

  return {
    monthOptionsObj,
    checkedList,
    onChangeCheckedList,
    onCheckAllChange,
    spalmaGiorni,
    removeMonths,
    clearForecastDays,
    clearForecastDaysFromID,
  };
};

export default useSpreadOutDays;
