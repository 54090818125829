/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the Azienda interface.
 */
export function instanceOfAzienda(value) {
    return true;
}
export function AziendaFromJSON(json) {
    return AziendaFromJSONTyped(json, false);
}
export function AziendaFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'] == null ? undefined : json['id'],
        'nome': json['nome'] == null ? undefined : json['nome'],
        'descrizione': json['descrizione'] == null ? undefined : json['descrizione'],
        'attivo': json['attivo'] == null ? undefined : json['attivo'],
        'logo': json['logo'] == null ? undefined : json['logo'],
        'denominazioneFirma': json['denominazioneFirma'] == null ? undefined : json['denominazioneFirma'],
        'sitoWeb': json['sitoWeb'] == null ? undefined : json['sitoWeb'],
        'acronimo': json['acronimo'] == null ? undefined : json['acronimo'],
        'idLegaleRappresentante': json['idLegaleRappresentante'] == null ? undefined : json['idLegaleRappresentante'],
        'note': json['note'] == null ? undefined : json['note'],
        'idOperatore': json['idOperatore'] == null ? undefined : json['idOperatore'],
        'dataModifica': json['dataModifica'] == null ? undefined : (new Date(json['dataModifica'])),
        'idAziendaAlyante': json['idAziendaAlyante'] == null ? undefined : json['idAziendaAlyante'],
    };
}
export function AziendaToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'nome': value['nome'],
        'descrizione': value['descrizione'],
        'attivo': value['attivo'],
        'logo': value['logo'],
        'denominazioneFirma': value['denominazioneFirma'],
        'sitoWeb': value['sitoWeb'],
        'acronimo': value['acronimo'],
        'idLegaleRappresentante': value['idLegaleRappresentante'],
        'note': value['note'],
        'idOperatore': value['idOperatore'],
        'dataModifica': value['dataModifica'] == null ? undefined : ((value['dataModifica']).toISOString()),
        'idAziendaAlyante': value['idAziendaAlyante'],
    };
}
