import { RevenueMonthDto } from '@api/client';
import { ValueType } from '../../../../../enums/global.enum';

export interface RevenueAllocation {
  readOnly: boolean;
  canShowTotal: boolean;
  revenueType: string;
  revenueEnum: RevenueEnum;
  revenueUnit: ValueType;
  revenueList: RevenueMonthDto[];
}

export enum RevenueEnum {
  RevenuePerMonthPercentage,
  RevenuePerMonth,
  CumulativeRevenuePercentage,
  CostsPerMonthPercentage,
  MarginPerMonthPercentage,
}
