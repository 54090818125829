/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AssociazioniFattureOrdiniDtoFromJSON, AssociazioniFattureOrdiniDtoToJSON, } from './AssociazioniFattureOrdiniDto';
/**
 * Check if a given object implements the FatturaEmessaDto interface.
 */
export function instanceOfFatturaEmessaDto(value) {
    return true;
}
export function FatturaEmessaDtoFromJSON(json) {
    return FatturaEmessaDtoFromJSONTyped(json, false);
}
export function FatturaEmessaDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'idFattura': json['idFattura'] == null ? undefined : json['idFattura'],
        'importo': json['importo'] == null ? undefined : json['importo'],
        'numeroFattura': json['numeroFattura'] == null ? undefined : json['numeroFattura'],
        'tipoFattura': json['tipoFattura'] == null ? undefined : json['tipoFattura'],
        'descrizione': json['descrizione'] == null ? undefined : json['descrizione'],
        'cliente': json['cliente'] == null ? undefined : json['cliente'],
        'fkCliente': json['fkCliente'] == null ? undefined : json['fkCliente'],
        'fkProgettoIntranet': json['fkProgettoIntranet'] == null ? undefined : json['fkProgettoIntranet'],
        'denominazioneProgettoPlus': json['denominazioneProgettoPlus'] == null ? undefined : json['denominazioneProgettoPlus'],
        'fattImporto': json['fattImporto'] == null ? undefined : json['fattImporto'],
        'fattNumero': json['fattNumero'] == null ? undefined : json['fattNumero'],
        'dataFattura': json['dataFattura'] == null ? undefined : (new Date(json['dataFattura'])),
        'settimanaMeseAnno': json['settimanaMeseAnno'] == null ? undefined : (new Date(json['settimanaMeseAnno'])),
        'meseAnno': json['meseAnno'] == null ? undefined : (new Date(json['meseAnno'])),
        'anno': json['anno'] == null ? undefined : json['anno'],
        'fattTipo': json['fattTipo'] == null ? undefined : json['fattTipo'],
        'denominazioneProgetto': json['denominazioneProgetto'] == null ? undefined : json['denominazioneProgetto'],
        'dataPagamento': json['dataPagamento'] == null ? undefined : (new Date(json['dataPagamento'])),
        'dataScadenza': json['dataScadenza'] == null ? undefined : (new Date(json['dataScadenza'])),
        'fattStatoContabile': json['fattStatoContabile'] == null ? undefined : json['fattStatoContabile'],
        'fattStato': json['fattStato'] == null ? undefined : json['fattStato'],
        'fattAging': json['fattAging'] == null ? undefined : json['fattAging'],
        'fattClassificazioneCrediti': json['fattClassificazioneCrediti'] == null ? undefined : json['fattClassificazioneCrediti'],
        'denominazioneCliente': json['denominazioneCliente'] == null ? undefined : json['denominazioneCliente'],
        'fkAzienda': json['fkAzienda'] == null ? undefined : json['fkAzienda'],
        'linkFattura': json['linkFattura'] == null ? undefined : json['linkFattura'],
        'numeroOrdine': json['numeroOrdine'] == null ? undefined : json['numeroOrdine'],
        'denominazioneAzienda': json['denominazioneAzienda'] == null ? undefined : json['denominazioneAzienda'],
        'denominazioneResponsabileContratto': json['denominazioneResponsabileContratto'] == null ? undefined : json['denominazioneResponsabileContratto'],
        'dataDaFattura': json['dataDaFattura'] == null ? undefined : (new Date(json['dataDaFattura'])),
        'dataAFattura': json['dataAFattura'] == null ? undefined : (new Date(json['dataAFattura'])),
        'denominazioneDescrizione': json['denominazioneDescrizione'] == null ? undefined : json['denominazioneDescrizione'],
        'pkOrdine': json['pkOrdine'] == null ? undefined : json['pkOrdine'],
        'fattNumOrdine': json['fattNumOrdine'] == null ? undefined : json['fattNumOrdine'],
        'associatedOrders': json['associatedOrders'] == null ? undefined : (json['associatedOrders'].map(AssociazioniFattureOrdiniDtoFromJSON)),
        'ifattAziendaAlyante': json['ifattAziendaAlyante'] == null ? undefined : json['ifattAziendaAlyante'],
    };
}
export function FatturaEmessaDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'idFattura': value['idFattura'],
        'importo': value['importo'],
        'numeroFattura': value['numeroFattura'],
        'tipoFattura': value['tipoFattura'],
        'descrizione': value['descrizione'],
        'cliente': value['cliente'],
        'fkCliente': value['fkCliente'],
        'fkProgettoIntranet': value['fkProgettoIntranet'],
        'denominazioneProgettoPlus': value['denominazioneProgettoPlus'],
        'fattImporto': value['fattImporto'],
        'fattNumero': value['fattNumero'],
        'dataFattura': value['dataFattura'] == null ? undefined : ((value['dataFattura']).toISOString().substring(0, 10)),
        'settimanaMeseAnno': value['settimanaMeseAnno'] == null ? undefined : ((value['settimanaMeseAnno']).toISOString().substring(0, 10)),
        'meseAnno': value['meseAnno'] == null ? undefined : ((value['meseAnno']).toISOString().substring(0, 10)),
        'anno': value['anno'],
        'fattTipo': value['fattTipo'],
        'denominazioneProgetto': value['denominazioneProgetto'],
        'dataPagamento': value['dataPagamento'] == null ? undefined : ((value['dataPagamento']).toISOString().substring(0, 10)),
        'dataScadenza': value['dataScadenza'] == null ? undefined : ((value['dataScadenza']).toISOString().substring(0, 10)),
        'fattStatoContabile': value['fattStatoContabile'],
        'fattStato': value['fattStato'],
        'fattAging': value['fattAging'],
        'fattClassificazioneCrediti': value['fattClassificazioneCrediti'],
        'denominazioneCliente': value['denominazioneCliente'],
        'fkAzienda': value['fkAzienda'],
        'linkFattura': value['linkFattura'],
        'numeroOrdine': value['numeroOrdine'],
        'denominazioneAzienda': value['denominazioneAzienda'],
        'denominazioneResponsabileContratto': value['denominazioneResponsabileContratto'],
        'dataDaFattura': value['dataDaFattura'] == null ? undefined : ((value['dataDaFattura']).toISOString().substring(0, 10)),
        'dataAFattura': value['dataAFattura'] == null ? undefined : ((value['dataAFattura']).toISOString().substring(0, 10)),
        'denominazioneDescrizione': value['denominazioneDescrizione'],
        'pkOrdine': value['pkOrdine'],
        'fattNumOrdine': value['fattNumOrdine'],
        'associatedOrders': value['associatedOrders'] == null ? undefined : (value['associatedOrders'].map(AssociazioniFattureOrdiniDtoToJSON)),
        'ifattAziendaAlyante': value['ifattAziendaAlyante'],
    };
}
