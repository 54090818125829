import { Controller, useForm } from 'react-hook-form';
import { DatePicker, Input, Select, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { t } from 'i18next';
import { useAppSelector } from '../../../core/store/hooks';
import { filtersEmesseSelector, filtersEmesseUsedSelector } from '../../../pages/private/active-cycle/_redux/selectors';
import LinksButton from '../../design-system/components/links-button';
import dayjs from 'dayjs';
import { filterOption } from '../../utils/common.utils';
import { MappedOrderOptions } from '../../../enums/orderOptions';

export const fattureFiltersFormData: any = {
  idFattura: '',
  numeroOrdine: '',
  startEndRange: [],
  supplier: undefined,
  legalEntity: undefined,
  excludefattureAssociate: true,
};
interface FattureFormProps {
  onSubmit: (data: any) => void;
  handleReset: any;
  setControlledDate: any;
  mappedPassiveCycleOptions?: MappedOrderOptions | undefined;
}
const FattureForm: React.FC<FattureFormProps> = ({
  onSubmit,
  handleReset,
  setControlledDate,
  mappedPassiveCycleOptions,
}) => {
  const { RangePicker } = DatePicker;
  const filtri = useAppSelector(filtersEmesseSelector);
  const filtriUsed = useAppSelector(filtersEmesseUsedSelector);
  const [formValues, setFormValues] = useState(fattureFiltersFormData);

  useEffect(() => {
    setFormValues({
      idFattura: filtriUsed?.idFattura || '',
      numeroOrdine: filtriUsed?.numeroOrdine || '',
      excludefattureAssociate: filtriUsed?.excludefattureAssociate || true,
    });
  }, [filtriUsed, filtri]);

  const { handleSubmit, control, register, setValue, reset } = useForm({
    mode: 'onChange',
    defaultValues: fattureFiltersFormData,
    values: formValues,
  });

  const Reset = () => {
    reset(fattureFiltersFormData);
    setControlledDate([]);
    handleReset();
  };

  return (
    <Form className="grid" onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col sm="3">
          <Form.Label>{t('ID bill')}</Form.Label>
          <Controller
            name="idFattura"
            control={control}
            render={({ field }) => (
              <Input
                type="text"
                placeholder={t('Insert ID bill')}
                size="large"
                allowClear
                style={{ width: '100%' }}
                value={field.value}
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
        </Col>
        <Col sm="3">
          <Form.Label>{t('Order number')}</Form.Label>
          <Controller
            name="numeroOrdine"
            control={control}
            render={({ field }) => (
              <Input
                type="text"
                placeholder={t('Insert order number')}
                size="large"
                allowClear
                style={{ width: '100%' }}
                value={field.value}
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
        </Col>

        <Col sm="3">
          {' '}
          <Form.Label>{t('Start/End date')}</Form.Label>
          <Controller
            name="startEndRange"
            control={control}
            defaultValue={[]}
            render={({ field }) => (
              <RangePicker
                placeholder={[
                  `${t('project-list.start-date-placeholder')}`,
                  `${t('project-list.end-date-placeholder')}`,
                ]}
                className="w-100 createDateRangePicker"
                popupClassName="createDateRangePicker"
                {...field}
                ref={field.ref}
                name={field.name}
                onBlur={field.onBlur}
                format="DD/MM/YYYY"
                value={field.value && field.value.length === 2 ? [dayjs(field.value[0]), dayjs(field.value[1])] : null}
                size="large"
                onChange={(dates: any) => {
                  field.onChange(dates ? dates.map((date: any) => date.format('YYYY-MM-DD')) : []);
                }}
              />
            )}
          />
        </Col>
        <Col sm="3">
          <Form.Label>{t('Exclude associated bills')}</Form.Label>
          <div>
            <Controller
              name="excludefattureAssociate"
              control={control}
              render={({ field }: any) => (
                <Switch
                  {...field}
                  checked={field.value}
                  onChange={(checked) => field.onChange(checked)}
                  className="mt-2"
                />
              )}
            />
          </div>
        </Col>
      </Row>
      {mappedPassiveCycleOptions && (
        <Row className="mt-3">
          <Col sm="3">
            {' '}
            <Form.Label>{t('Supplier')}</Form.Label>
            <Controller
              name="supplier"
              control={control}
              render={({ field }) => (
                <Select
                  placeholder={t('Select supplier')}
                  size="large"
                  allowClear
                  optionFilterProp="children"
                  filterOption={filterOption}
                  className="w-100"
                  {...field}
                >
                  {mappedPassiveCycleOptions?.fornitoriList?.map((el, index) => (
                    <Select.Option key={index} value={el.id}>
                      {el.denominazione}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
          </Col>
          <Col sm="3">
            {' '}
            <Form.Label>{t('Legal entity')}</Form.Label>
            <Controller
              name="legalEntity"
              control={control}
              render={({ field }) => (
                <Select
                  placeholder={t('Select legal entity')}
                  size="large"
                  allowClear
                  optionFilterProp="children"
                  filterOption={filterOption}
                  className="w-100"
                  {...field}
                >
                  {mappedPassiveCycleOptions?.aziendaList?.map((entity) => (
                    <Select.Option key={entity.id} value={entity.id}>
                      {entity.nome}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
          </Col>
        </Row>
      )}

      <div className="d-flex">
        <div className="d-flex ms-auto">
          <Button className="btn text-danger trash-btn" type="button" variant="icon" onClick={Reset}>
            <i className="fa-regular fa-trash-can me-2"></i>
            {t('project-list.delete-filters')}
          </Button>
          <LinksButton type="submit" className="btn-sm btn-primary btn-links px-4">
            <i className="fa-solid fa-magnifying-glass me-2"></i>
            {t('project-list.search')}
          </LinksButton>
        </div>
      </div>
    </Form>
  );
};

export default FattureForm;
