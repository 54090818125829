/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CategoriaMerceologicaFromJSON, CategoriaMerceologicaToJSON, } from './CategoriaMerceologica';
/**
 * Check if a given object implements the FornitoreDto interface.
 */
export function instanceOfFornitoreDto(value) {
    return true;
}
export function FornitoreDtoFromJSON(json) {
    return FornitoreDtoFromJSONTyped(json, false);
}
export function FornitoreDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'] == null ? undefined : json['id'],
        'denominazione': json['denominazione'] == null ? undefined : json['denominazione'],
        'partitaIva': json['partitaIva'] == null ? undefined : json['partitaIva'],
        'sedeLegale': json['sedeLegale'] == null ? undefined : json['sedeLegale'],
        'sedeOperativa': json['sedeOperativa'] == null ? undefined : json['sedeOperativa'],
        'numeroTelefono': json['numeroTelefono'] == null ? undefined : json['numeroTelefono'],
        'email': json['email'] == null ? undefined : json['email'],
        'pec': json['pec'] == null ? undefined : json['pec'],
        'sitoWeb': json['sitoWeb'] == null ? undefined : json['sitoWeb'],
        'note': json['note'] == null ? undefined : json['note'],
        'categoriaMerceologica': json['categoriaMerceologica'] == null ? undefined : CategoriaMerceologicaFromJSON(json['categoriaMerceologica']),
        'performance': json['performance'] == null ? undefined : json['performance'],
        'feedbackInterno': json['feedbackInterno'] == null ? undefined : json['feedbackInterno'],
        'nonConformita': json['nonConformita'] == null ? undefined : json['nonConformita'],
        'indiceAffidabilita': json['indiceAffidabilita'] == null ? undefined : json['indiceAffidabilita'],
    };
}
export function FornitoreDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'denominazione': value['denominazione'],
        'partitaIva': value['partitaIva'],
        'sedeLegale': value['sedeLegale'],
        'sedeOperativa': value['sedeOperativa'],
        'numeroTelefono': value['numeroTelefono'],
        'email': value['email'],
        'pec': value['pec'],
        'sitoWeb': value['sitoWeb'],
        'note': value['note'],
        'categoriaMerceologica': CategoriaMerceologicaToJSON(value['categoriaMerceologica']),
        'performance': value['performance'],
        'feedbackInterno': value['feedbackInterno'],
        'nonConformita': value['nonConformita'],
        'indiceAffidabilita': value['indiceAffidabilita'],
    };
}
