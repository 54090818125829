import AuthService from '../core/auth/auth.service';
import { PathEnum } from '../core/router/path.enum';

export const generateSidebarLinks = (t: any) => [
  {
    path: `/${PathEnum.PRIVATE}/${PathEnum.DASHBOARD}`,
    label: t('common.dashboard'),
    icon: 'icon-dashboard',
    authCheck: () => AuthService.isAm() || AuthService.isPl() || AuthService.isDm(),
    disabled: false,
  },
  {
    path: `/${PathEnum.PRIVATE}/${PathEnum.PROGETTI}`,
    label: t('common.projects'),
    icon: 'icon-duplicate-file',
    authCheck: () => true,
    disabled: false,
  },
  {
    path: `/${PathEnum.PRIVATE}/${PathEnum.CICLO_PASSIVO}`,
    label: t('common.passive-cycle'),
    icon: 'icon-ciclo-passivo fs-6 ms-1',
    authCheck: () => AuthService.isCp(),
    disabled: false,
  },
  {
    path: `/${PathEnum.PRIVATE}/${PathEnum.ACTIVE_CYCLE}`,
    label: t('Active Cycle'),
    icon: 'icon-calendar-option',
    authCheck: () => AuthService.isCa(),
    disabled: false,
  },
  {
    path: `/${PathEnum.PRIVATE}/${PathEnum.PL}`,
    label: t('common.pl'),
    icon: 'icon-pl-management-tools',
    authCheck: () => AuthService.canViewPlPage(),
    disabled: false,
  },
  {
    path: `/${PathEnum.PRIVATE}/${PathEnum.CL}`,
    label: t('common.cl'),
    icon: 'icon-cl-management-tools',
    authCheck: () => AuthService.isAm(),
    disabled: false,
  },
  {
    path: `/${PathEnum.PRIVATE}/${PathEnum.CDG}`,
    label: t('common.cdg'),
    icon: 'icon-configurazione-documento',
    authCheck: () => AuthService.isCgd(),
    disabled: false,
  },
  {
    path: `/${PathEnum.PRIVATE}/${PathEnum.CRUD}`,
    label: t('common.crud'),
    icon: 'icon-file-policy',
    authCheck: () => AuthService.isSuperAdmin(),
    disabled: false,
  },
  {
    path: `/${PathEnum.PRIVATE}/${PathEnum.WFM}`,
    label: t('common.freeze-month'),
    icon: 'icon-file-error',
    authCheck: () => AuthService.isWFM(),
    disabled: false,
  },
  {
    path: `/${PathEnum.PRIVATE}/${PathEnum.DELEGATE_MANAGEMENT}`,
    label: t('common.delegates-management'),
    icon: 'icon-delega',
    authCheck: () => AuthService.isHCC(),
    disabled: false,
  },
];
