/* eslint-disable eqeqeq */
import { Input, InputNumber, Select, Tooltip } from 'antd';
import RevenuePlanActionsHeader from '../revenue-plan-actions-header';
import './styles.scss';
import React, { useState } from 'react';
import {
  AllocationDto,
  BaselineOffertaDto,
  DettaglioProjectDto,
  ProfiloProfessionaleDto,
  RevenueMonthDto,
  RevenuePlanDto,
  StaffExtendedDto,
  UserDto,
} from '@api/client';
import { DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import SpreadOutComponent from '../spread-out-component';
import ActionsMenuComponent from '../actions-menu-component';
import {
  filterOption,
  formatCurrency,
  formatCurrencyNumber,
  getFormattedDateHeader,
} from '../../../../../../shared/utils/common.utils';
import { getAllocationLavorate, isAllocationWorked } from '../../_utils/revenue-plan.utils';
import { useTranslation } from 'react-i18next';
import useStaffManagement from '../../_hooks/team-table/useStaffManagement';
import MoveDaysModal from '../move-days-modal';
import useSpreadOutDays from '../../_hooks/useSpreadOutDays';
import useValidationGu from '../../_hooks/team-table/useValidationGu';
import { Table } from 'react-bootstrap';

interface Props {
  projectDetails: DettaglioProjectDto | null;
  idProject: number | undefined;
  readOnly: boolean | undefined;
  visibleRows: { [key: string]: boolean };
  visibleColumns: { [key: string]: boolean };
  fullAllocations: Partial<StaffExtendedDto>[] | null | undefined;
  fullAllocationsShown: Partial<StaffExtendedDto>[];
  isWorkInProgress: boolean;
  years: (number | undefined)[];
  roleList: ProfiloProfessionaleDto[];
  collaborators: UserDto[];
  currentYear: number | 'all';
  totalGUPlanned: number[];
  teamTableRef: React.RefObject<HTMLTableElement>;
  baselineOfferta: BaselineOffertaDto | undefined;
  isTotalsTableCollapsed: boolean;
  // ricaviMeseDaConsolidare: RevenueMonthDto | undefined;
  // revenueMonthToBeConsolidated: number | undefined;
  // setRevenueMonthToBeConsolidated: React.Dispatch<React.SetStateAction<number | undefined>>;
  setIsTotalsTableCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  setTotalGuValidation: React.Dispatch<React.SetStateAction<boolean>>;
  setVisibleRows: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
  setVisibleColumns: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
  setTotalGUPlanned: React.Dispatch<React.SetStateAction<number[]>>;
  handleParentScroll: (event: React.UIEvent<HTMLDivElement>) => void;
  setFullAllocations: React.Dispatch<React.SetStateAction<Partial<StaffExtendedDto>[] | null | undefined>>;
  setDraftValidation: React.Dispatch<React.SetStateAction<boolean>>;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  setYears: React.Dispatch<React.SetStateAction<(number | undefined)[]>>;
  filterAllocationsByYear: (
    year: number | 'all',
    allocations?: Partial<StaffExtendedDto>[] | null | undefined,
    revenuePlan?: RevenuePlanDto[] | null | undefined
  ) => void;
}

const TeamTable: React.FC<Props> = ({
  projectDetails,
  idProject,
  readOnly,
  visibleRows,
  visibleColumns,
  fullAllocations,
  fullAllocationsShown,
  isWorkInProgress,
  years,
  roleList,
  collaborators,
  currentYear,
  totalGUPlanned,
  teamTableRef,
  baselineOfferta,
  isTotalsTableCollapsed,
  // ricaviMeseDaConsolidare,
  // revenueMonthToBeConsolidated,
  // setRevenueMonthToBeConsolidated,
  setIsTotalsTableCollapsed,
  setTotalGuValidation,
  setVisibleRows,
  setVisibleColumns,
  setTotalGUPlanned,
  handleParentScroll,
  setFullAllocations,
  setDraftValidation,
  setRefresh,
  setYears,
  filterAllocationsByYear,
}: Props) => {
  const { t } = useTranslation();
  const [showModalMoveDays, setShowModalMoveDays] = useState(false);
  const [currentCollaborator, setCurrentCollaborator] = useState<any | undefined>();
  const [currentCollaboratorName, setCurrentCollaboratorName] = useState('');
  const [currentRole, setCurrentRole] = useState('');
  const [currentGu, setCurrentGu] = useState<number>(0);
  const [dateRange, setDateRange] = useState<number[]>([]);

  const {
    handleChangeAllocation,
    handleChangeTotalGU,
    handleChangeCollaborator,
    handleChangeProfile,
    getOptions,
    addMonths,
    addCollaborator,
    deleteCollaborator,
    disableAllocatedMonths,
  } = useStaffManagement(
    fullAllocations,
    setFullAllocations,
    collaborators,
    filterAllocationsByYear,
    currentYear,
    setTotalGUPlanned,
    setDraftValidation,
    setRefresh,
    setYears,
    dateRange
    // ricaviMeseDaConsolidare,
    // revenueMonthToBeConsolidated
  );

  const { monthOptionsObj, checkedList, onChangeCheckedList, onCheckAllChange, removeMonths, clearForecastDays } =
    useSpreadOutDays(
      fullAllocations,
      currentYear,
      totalGUPlanned,
      idProject,
      setFullAllocations,
      filterAllocationsByYear
    );

  const { areTotalsGUMatch, getTooltip } = useValidationGu({
    totalGUPlanned,
    fullAllocations,
    setTotalGuValidation,
  });

  const handleShowMoveDaysModal = (index: number) => {
    setShowModalMoveDays(true);
    setCurrentCollaborator(fullAllocationsShown![index]);
    const collaboratorName = fullAllocationsShown![index].name;
    const collaboratorRole = roleList.find((el) => el.idPps == fullAllocationsShown![index].idPps)?.descrizionePps;
    const collaboratorSeniority = roleList
      .find((el) => el.idPps == fullAllocationsShown![index].idPps)
      ?.pptList?.find((el) => el.idCostoProfilo == fullAllocationsShown![index].idPpt)?.descrizioneCostoProfilo;
    setCurrentCollaboratorName(collaboratorName!);
    setCurrentRole(`${collaboratorRole!} - ${collaboratorSeniority}`);
    const totalGu = totalGUPlanned?.[index]?.toFixed(2);
    setCurrentGu(+totalGu);
  };

  const getCurrentMonthRendicontations = (index: number) => {
    const a = fullAllocations?.[index]?.allocazioni?.find(
      (el) => el.month === new Date().getMonth() + 1 && el.year === new Date().getFullYear()
    )?.realTimeAllocation;
    return a || 0;
  };

  return (
    <div>
      <RevenuePlanActionsHeader
        readOnly={readOnly}
        monthOptionsObj={monthOptionsObj}
        checkedList={checkedList}
        years={years}
        // ricaviMeseDaConsolidare={ricaviMeseDaConsolidare}
        // baselineOfferta={baselineOfferta}
        // revenueMonthToBeConsolidated={revenueMonthToBeConsolidated}
        fullAllocationsShown={fullAllocationsShown}
        visibleRows={visibleRows}
        isWorkInProgress={isWorkInProgress}
        visibleColumns={visibleColumns}
        isTotalsTableCollapsed={isTotalsTableCollapsed}
        setIsTotalsTableCollapsed={setIsTotalsTableCollapsed}
        setVisibleRows={setVisibleRows}
        setVisibleColumns={setVisibleColumns}
        addCollaborator={addCollaborator}
        addMonths={addMonths}
        setDateRange={setDateRange}
        disableAllocatedMonths={disableAllocatedMonths}
        removeMonths={removeMonths}
        onChangeCheckedList={onChangeCheckedList}
        onCheckAllChange={onCheckAllChange}
        // setRevenueMonthToBeConsolidated={setRevenueMonthToBeConsolidated}
        filterAllocationsByYear={filterAllocationsByYear}
      />

      <div
        className="d-flex table-responsive table-bordered container-table"
        style={{ maxHeight: 'calc(100vh - 420px)', overflowY: 'auto', overflowX: 'auto' }}
        onScroll={handleParentScroll}
        ref={teamTableRef}
      >
        <Table className={'revenue-table sticky-table mt-0 mb-0'} style={{ maxWidth: '200px' }}>
          <thead className="fixed-sticky-header">
            <tr>
              {visibleColumns.ppsProfile && (
                <th className="text-left">
                  <span>{t('project-detail.pps-profile')}</span>
                </th>
              )}
              {visibleColumns.pptSeniority && (
                <th className="text-left" style={{ minWidth: '170px', maxWidth: '170px' }}>
                  <span>{t('project-detail.ppt-seniority')}</span>
                </th>
              )}
              {visibleColumns.name && (
                <th className="text-left">
                  <span>{t('project-detail.name')}</span>
                </th>
              )}

              {visibleColumns.guRemaining && (
                <th className="text-left" style={{ minWidth: isWorkInProgress ? '190px' : '130px' }}>
                  <span>
                    {isWorkInProgress ? `${t('project-detail.gu-remaining')}` : `${t('project-detail.planned-gu')}`}
                  </span>
                </th>
              )}
              {visibleColumns.currentMonthRendicontations && (
                <th className="text-left bg-disabled" style={{ minWidth: '110px' }}>
                  <span>{t('project-detail.current-month-rendicontations')}</span>
                  <Tooltip title={t('project-detail.current-month-rendicontations-tooltip')} color="white">
                    <i className="fas fa-circle-info text-blue-links ps-2" />
                  </Tooltip>
                </th>
              )}
              {isWorkInProgress && (
                <>
                  {visibleColumns.worked && (
                    <th className="text-left bg-disabled" style={{ minWidth: '100px' }}>
                      <span>{t('project-detail.worked')}</span>
                    </th>
                  )}
                  {visibleColumns.totalPlanned && (
                    <th className="text-left bg-disabled" style={{ minWidth: '100px' }}>
                      <span>{t('project-detail.total-planned')}</span>
                    </th>
                  )}
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {fullAllocationsShown?.map((teamMember: StaffExtendedDto, index: number) => (
              <tr key={'row-' + index}>
                {visibleColumns.ppsProfile && (
                  <td
                    className="text-left p-0"
                    key={'Profilo-' + index}
                    style={{ minWidth: '200px', maxWidth: '200px' }}
                  >
                    <Select
                      className="w-100"
                      placeholder={t('project-detail.pps-profile-placeholder')}
                      showSearch
                      optionFilterProp="children"
                      filterOption={filterOption}
                      disabled={
                        readOnly ||
                        (isWorkInProgress &&
                          (Number(
                            getAllocationLavorate(fullAllocations, index)?.reduce(
                              (acc, el) => acc + (el.actualAllocation ?? 0),
                              0
                            )
                          ) ?? 0) !== 0)
                      }
                      value={
                        teamMember.idPps
                          ? {
                              value: teamMember.idPps,
                              label: roleList.find((el) => el.idPps == teamMember.idPps)?.descrizionePps,
                            }
                          : undefined
                      }
                      allowClear
                      variant="borderless"
                      onChange={(e) => handleChangeProfile(e, index, 'idPps')}
                    >
                      {roleList.map((el) => (
                        <Select.Option key={`${el.idPps}-${index}`} value={el.idPps}>
                          {el.descrizionePps}
                        </Select.Option>
                      ))}
                    </Select>
                  </td>
                )}
                {visibleColumns.pptSeniority && (
                  <td
                    className="text-left p-0"
                    key={'Seniority-' + index}
                    style={{ minWidth: '200px', maxWidth: '200px' }}
                  >
                    <Select
                      className="w-100"
                      placeholder={t('project-detail.ppt-seniority-placeholder')}
                      showSearch
                      optionFilterProp="children"
                      filterOption={filterOption}
                      disabled={
                        readOnly ||
                        (isWorkInProgress &&
                          (Number(
                            getAllocationLavorate(fullAllocations, index)?.reduce(
                              (acc, el) => acc + (el.actualAllocation ?? 0),
                              0
                            )
                          ) ?? 0) !== 0)
                      }
                      value={
                        teamMember.idPpt
                          ? {
                              value: teamMember.idPpt,
                              label: roleList
                                .find((el) => el.idPps == teamMember.idPps)
                                ?.pptList?.find((el) => el.idCostoProfilo == teamMember.idPpt)?.descrizioneCostoProfilo,
                            }
                          : undefined
                      }
                      allowClear
                      variant="borderless"
                      onChange={(e) => handleChangeProfile(e, index, 'idPpt')}
                    >
                      {roleList
                        .find((el) => el.idPps == teamMember.idPps)
                        ?.pptList?.map((el) => (
                          <Select.Option key={`${el.idCostoProfilo}-${index}`} value={el.idCostoProfilo}>
                            {el.descrizioneCostoProfilo}
                          </Select.Option>
                        ))}
                    </Select>
                  </td>
                )}
                {visibleColumns.name && (
                  <td
                    className="text-left p-0"
                    key={'Collaboratore-' + index}
                    style={{ minWidth: '180px', maxWidth: '180px' }}
                  >
                    <Select
                      className="w-100"
                      placeholder={t('project-detail.name-placeholder')}
                      variant="borderless"
                      showSearch
                      optionFilterProp="children"
                      filterOption={filterOption}
                      suffixIcon={
                        teamMember.active === false ? (
                          <Tooltip title={`Inactive team member`} key={index}>
                            <ExclamationCircleOutlined className="pe-3 text-red" />
                          </Tooltip>
                        ) : (
                          <DownOutlined style={{ pointerEvents: 'none' }} />
                        )
                      }
                      disabled={
                        readOnly ||
                        (isWorkInProgress &&
                          (Number(
                            getAllocationLavorate(fullAllocations, index)?.reduce(
                              (acc, el) => acc + (el.actualAllocation ?? 0),
                              0
                            )
                          ) ?? 0) !== 0 &&
                          teamMember.name !== null)
                      }
                      value={
                        teamMember.userId && teamMember.name
                          ? {
                              value: teamMember.userId,
                              label:
                                collaborators?.find((el) => el.id == teamMember.userId)?.nominativo ||
                                fullAllocationsShown?.[index]?.name,
                            }
                          : undefined
                      }
                      onChange={(e) => {
                        handleChangeCollaborator(e, index);
                      }}
                    >
                      {getOptions(teamMember.idPps, teamMember.idPpt).map((el) => (
                        <Select.Option key={`${el.value}-${index}`} value={el.value}>
                          {el.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </td>
                )}
                {visibleColumns.guRemaining && (
                  <td className="text-center p-0" key={'GU-' + index}>
                    <div className="d-flex justify-content-between">
                      <Tooltip
                        title={areTotalsGUMatch?.[index] ? '' : getTooltip(index)}
                        color="red"
                        key={`tooltip-total-${index}`}
                        className="w-100"
                      >
                        <InputNumber
                          value={Number(totalGUPlanned?.[index]?.toFixed(2)) ?? 0}
                          onChange={(e) => handleChangeTotalGU(e, index)}
                          decimalSeparator=","
                          variant="borderless"
                          readOnly={readOnly}
                          className={areTotalsGUMatch?.[index] ? '' : 'red-label'}
                          suffix={
                            <Tooltip title={`text`} key={index}>
                              <ExclamationCircleOutlined
                                className="pe-3"
                                style={{ color: !areTotalsGUMatch?.[index] ? 'red' : 'transparent' }}
                              />
                            </Tooltip>
                          }
                        />
                      </Tooltip>
                      <SpreadOutComponent
                        index={index}
                        readOnly={readOnly}
                        years={years}
                        fullAllocations={fullAllocations}
                        fullAllocationsShown={fullAllocationsShown}
                        currentYear={currentYear}
                        totalGUPlanned={totalGUPlanned}
                        idProject={idProject}
                        currentCollaboratorName={currentCollaboratorName}
                        currentGu={currentGu}
                        setFullAllocations={setFullAllocations}
                        setCurrentCollaboratorName={setCurrentCollaboratorName}
                        setCurrentGu={setCurrentGu}
                        filterAllocationsByYear={filterAllocationsByYear}
                      />
                      <ActionsMenuComponent
                        index={index}
                        idProject={idProject}
                        totalGUPlanned={totalGUPlanned}
                        fullAllocations={fullAllocations}
                        clearForecastDays={clearForecastDays}
                        handleShowMoveDaysModal={handleShowMoveDaysModal}
                        deleteCollaborator={deleteCollaborator}
                      />
                    </div>
                  </td>
                )}
                {visibleColumns.currentMonthRendicontations && (
                  <td className="text-left p-0 bg-disabled" key={'Rend-' + index}>
                    <Input
                      readOnly
                      className="bg-disabled"
                      value={formatCurrency(getCurrentMonthRendicontations(index))}
                    />
                  </td>
                )}
                {isWorkInProgress && (
                  <>
                    {visibleColumns.worked && (
                      <td className="text-left p-0 bg-disabled" key={'Lavorati-' + index}>
                        <Input
                          readOnly
                          className="bg-disabled"
                          value={formatCurrency(
                            Number(
                              getAllocationLavorate(fullAllocations, index)?.reduce(
                                (acc, el) => acc + (el.actualAllocation ?? 0),
                                0
                              )
                            ) ?? 0
                          )}
                        />
                      </td>
                    )}
                    {visibleColumns.totalPlanned && (
                      <td className="text-left p-0 bg-disabled" key={'Tot/Pian-' + index}>
                        <Input
                          readOnly
                          className="bg-disabled"
                          value={formatCurrency(
                            totalGUPlanned?.[index] +
                              Number(
                                getAllocationLavorate(fullAllocations, index)?.reduce(
                                  (acc, el) => acc + (el.actualAllocation ?? 0),
                                  0
                                )
                              )
                          )}
                        />
                      </td>
                    )}
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </Table>

        <Table className={'revenue-table mt-0 mb-0'}>
          <thead>
            <tr className="fixed-scroll-header">
              {fullAllocationsShown?.[0]?.allocazioni?.map((allocation: AllocationDto, index: number) => (
                <th
                  className={`text-left ${isAllocationWorked(allocation) && 'bg-disabled'}`}
                  scope="col"
                  key={'GU-' + index}
                  style={{ minWidth: '100px', maxWidth: '100px' }}
                >
                  <span>{getFormattedDateHeader(allocation?.month, allocation?.year)}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {fullAllocationsShown?.map((teamMember: StaffExtendedDto, index: number) => (
              <React.Fragment key={'row-' + index}>
                <tr key={'row-' + index}>
                  {teamMember.allocazioni?.map((allocation: AllocationDto, index: number) => (
                    <td
                      className={`text-center p-0 ${isAllocationWorked(allocation) && 'bg-disabled'}`}
                      key={'allocation-' + index}
                    >
                      <InputNumber
                        className={`w-100 ${
                          (allocation?.actualAllocation || 0) <=
                          (projectDetails?.giorniLavorativi?.find(
                            (el) => el.mese === allocation?.month && el.anno === allocation?.year
                          )?.giorniLavorativiReali || 26)
                            ? ''
                            : 'red-label'
                        }`}
                        key={'GU-per-month-' + index}
                        value={formatCurrencyNumber(allocation?.actualAllocation, 2) ?? 0}
                        decimalSeparator=","
                        readOnly={readOnly}
                        disabled={allocation?.editEnable === false}
                        min={0}
                        max={31}
                        onChange={(e) => handleChangeAllocation(e, allocation?.id)}
                        variant="borderless"
                      />
                    </td>
                  ))}
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </div>
      <MoveDaysModal
        showModalMoveDays={showModalMoveDays}
        collaborators={collaborators}
        currentCollaborator={currentCollaborator}
        fullAllocations={fullAllocations}
        fullAllocationsShown={fullAllocationsShown}
        currentYear={currentYear}
        currentCollaboratorName={currentCollaboratorName}
        currentRole={currentRole}
        currentGu={currentGu}
        roleList={roleList}
        setFullAllocations={setFullAllocations}
        filterAllocationsByYear={filterAllocationsByYear}
        setDraftValidation={setDraftValidation}
        setShowModalMoveDays={setShowModalMoveDays}
        getOptions={getOptions}
      />
    </div>
  );
};

export default TeamTable;
