import React, { useEffect, useState } from 'react';
import LinksButton from '../../../../shared/design-system/components/links-button';
import ProjectData from './_components/project-data';
import { useAppDispatch } from '../../../../core/store/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from 'antd';
import { getCosts, getUsers, resetTeamAndCostsData, saveRevenueProgress } from './_redux/actions';
import { DettagliProgettoDtoStatoEnum, SearchUsersRequest } from '@api/client';
import StatusBadge from '../../../../shared/design-system/components/project-status-badge';
import StartRevenuePlanning from './_components/start-revenue-plan';
import RevenuePlan from './_components/revenue-plan';
import useCalculateIndicatori from './_hooks/useCalculateIndicatori';
import { useTranslation } from 'react-i18next';
import { PathEnum } from '../../../../core/router/path.enum';
import useRoles from './_hooks/project-detail/useRoles';
import useProjectDetails from './_hooks/project-detail/useProjectDetails';
import useTeamManagement from './_hooks/project-detail/useTeamManagement';
import useDateRange from './_hooks/project-detail/useDateRange';
import useProjectActions from './_hooks/project-detail/useProjectActions';
import AppBlocker from './_components/blocker';
import { type unstable_BlockerFunction as BlockerFunction } from 'react-router-dom';
import { trackUserIdGa4 } from '../../../../shared/utils/gtag';
import AuthService from '../../../../core/auth/auth.service';
import { crudGetProject } from '../../crud/_redux/actions';
import { TrackPagesGA4Enum } from '../../../../enums/global.enum';
import { resetBillingPlan } from './_components/billing-plan/_redux/actions';
import PlanByTeamModal from './_components/plan-by-team-modal';

const ProjectDetail: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: any }>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const roleList = useRoles();
  const { projectDetails, setProjectDetails, isPlanning, isWorkInProgress, getProjectDetail } = useProjectDetails();
  const { dateRange, onDateChange, getDefaultDateRange } = useDateRange(projectDetails?.mesiProgetto);
  const { ruoliTeam, handleRoleChange, handleSeniorityChange, handleChangeAmounts, addRole, deleteRole } =
    useTeamManagement(roleList);
  const { indicatori } = useCalculateIndicatori(projectDetails, ruoliTeam);
  const [totalGuValidation, setTotalGuValidation] = useState(true);
  const [totalCostsValidation, setTotalCostsValidation] = useState(true);
  const [draftValidation, setDraftValidation] = useState(false);
  const [costDescriptionValidation, setCostDescriptionValidation] = useState(true);
  const [associatedCostsValidation, setAssociatedCostsValidation] = useState(true);
  const [revenueProgressValidation, setRevenueProgressValidation] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const { createTeam, saveAndConsolidaPlanning, isConfermaEnabled, startPlanningInTable } = useProjectActions(
    projectDetails!,
    setProjectDetails,
    id,
    ruoliTeam,
    dateRange,
    handleCloseModal,
    getProjectDetail,
    isPlanning,
    costDescriptionValidation,
    associatedCostsValidation,
    revenueProgressValidation
  );

  useEffect(() => {
    trackUserIdGa4(TrackPagesGA4Enum.PROJECT_DETAIL);
    dispatch(getCosts());
    const request: SearchUsersRequest = {
      userSearchRequest: {},
    };
    dispatch(getUsers(request));

    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
      event.returnValue = '';
      return '';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      dispatch(resetBillingPlan());
      dispatch(resetTeamAndCostsData());
      dispatch(saveRevenueProgress(undefined));
    };
  }, []);

  const goToEditProject = () => {
    if (AuthService.isSuperAdmin()) {
      dispatch(crudGetProject(id))
        .unwrap()
        .then((res) => {
          if (res) navigate(`/${PathEnum.PRIVATE}/${PathEnum.CRUD}`);
        });
    } else {
      navigate(`/${PathEnum.PRIVATE}/${PathEnum.CRUD}`);
    }
  };

  let shouldBlock = React.useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname &&
      (projectDetails?.staffList?.length ?? 0) > 0 &&
      !draftValidation,
    [draftValidation]
  );

  const canViewRevenuePlan = () => {
    return (projectDetails?.staffList?.length ?? 0) > 0;
  };
  return (
    <>
      <AppBlocker shouldBlock={shouldBlock} />
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center col-8">
          <h3>
            ({projectDetails?.dettaglioOfferta?.intranetId}) {projectDetails?.dettaglioOfferta?.name}
          </h3>
          <div className="align-center d-flex">
            <StatusBadge label={true} className="mt-2 ms-3">
              {projectDetails?.dettaglioOfferta?.stato}
            </StatusBadge>
          </div>
        </div>
        {!costDescriptionValidation && (
          <Tooltip
            title={t('project-detail.error-description-other-costs-validation')}
            color="white"
            className="cursor-pointer ms-auto pe-3"
          >
            <i className="fa-solid fa-triangle-exclamation me-1 text-danger"></i>
          </Tooltip>
        )}

        {projectDetails?.dettaglioOfferta?.stato !== DettagliProgettoDtoStatoEnum.Pending && (
          <div className="d-flex">
            <LinksButton
              className="btn-sm btn-primary btn-teal px-2"
              onClick={goToEditProject}
              isDisabled={!projectDetails?.editDeliveryEnabled && projectDetails?.readOnly}
            >
              <i className="icon-edit" />
            </LinksButton>

            <LinksButton
              className="btn-sm btn-primary btn-teal px-4"
              onClick={saveAndConsolidaPlanning}
              isDisabled={!totalGuValidation || !totalCostsValidation || projectDetails?.readOnly}
            >
              <i className="fas fa-check me-2" />
              {isWorkInProgress ? `${t('project-detail.save-planning')}` : `${t('project-detail.send-planning')}`}
            </LinksButton>
          </div>
        )}
      </div>

      <ProjectData projectDetails={projectDetails} />
      {canViewRevenuePlan() ? (
        <RevenuePlan
          projectDetails={projectDetails}
          roleList={roleList}
          idProject={projectDetails?.dettaglioOfferta?.id}
          isWorkInProgress={isWorkInProgress}
          setTotalGuValidation={setTotalGuValidation}
          setTotalCostsValidation={setTotalCostsValidation}
          setDraftValidation={setDraftValidation}
          setCostDescriptionValidation={setCostDescriptionValidation}
          setAssociatedCostsValidation={setAssociatedCostsValidation}
          setRevenueProgressValidation={setRevenueProgressValidation}
        />
      ) : (
        <StartRevenuePlanning
          readOnly={projectDetails?.readOnly}
          projectMonths={projectDetails?.mesiProgetto}
          handleShowModal={handleShowModal}
          startPlanningInTable={startPlanningInTable}
        />
      )}

      <PlanByTeamModal
        showModal={showModal}
        indicatori={indicatori}
        ruoliTeam={ruoliTeam}
        roleList={roleList}
        handleCloseModal={handleCloseModal}
        onDateChange={onDateChange}
        getDefaultDateRange={getDefaultDateRange}
        addRole={addRole}
        handleRoleChange={handleRoleChange}
        handleChangeAmounts={handleChangeAmounts}
        handleSeniorityChange={handleSeniorityChange}
        deleteRole={deleteRole}
        isConfermaEnabled={isConfermaEnabled}
        createTeam={createTeam}
      />
    </>
  );
};

export default ProjectDetail;
