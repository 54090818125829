import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../core/store';

const projectListReducer = (state: RootState) => state.private.progetti;

export const progettiInEvidenzaSelector = createSelector(projectListReducer, (progetti) => progetti.progettiInEvidenza);
export const filtriProgettiSelector = createSelector(projectListReducer, (progetti) => progetti.filters);
export const currentProjectSelector = createSelector(projectListReducer, (progetti) => progetti.currentProject);
export const currentProjectTypeSelector = createSelector(projectListReducer, (progetti) => progetti.currentProjectType);
export const progettiFiltratiSelector = createSelector(projectListReducer, (progetti) => progetti.progettiFiltrati);
export const filtersUsedSelector = createSelector(projectListReducer, (progetti) => progetti.filtersUsed);
export const currentProjectRevenueSelector = createSelector(
  projectListReducer,
  (progetti) => progetti.currentProject?.baselineOfferta?.importoOfferta
);
export const currentProjectTotalCostSelector = createSelector(
  projectListReducer,
  (progetti) => progetti.currentProject?.baselineOfferta?.costoTotaleOfferta
);
