/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AziendaFromJSON, AziendaToJSON, } from './Azienda';
import { BusinessManagerDtoFromJSON, BusinessManagerDtoToJSON, } from './BusinessManagerDto';
import { ClienteDtoFromJSON, ClienteDtoToJSON, } from './ClienteDto';
import { CodiceCostoRicavoDtoFromJSON, CodiceCostoRicavoDtoToJSON, } from './CodiceCostoRicavoDto';
import { FilterDtoFromJSON, FilterDtoToJSON, } from './FilterDto';
import { ProjectOrganizationDtoFromJSON, ProjectOrganizationDtoToJSON, } from './ProjectOrganizationDto';
import { ProjectUserDtoFromJSON, ProjectUserDtoToJSON, } from './ProjectUserDto';
/**
 * @export
 */
export const SearchFilterDtoStatiEnum = {
    Pending: 'PENDING',
    Planning: 'PLANNING',
    ClosedLost: 'CLOSED_LOST',
    IntranetPending: 'INTRANET_PENDING',
    WorkInProgress: 'WORK_IN_PROGRESS',
    Completed: 'COMPLETED',
    ClosedWonPlanning: 'CLOSED_WON_PLANNING',
    PlanningOk: 'PLANNING_OK',
    ControlloCdg: 'CONTROLLO_CDG',
    ControlloCl: 'CONTROLLO_CL',
    ReportingClosed: 'REPORTING_CLOSED',
    EconomicsClosed: 'ECONOMICS_CLOSED'
};
/**
 * @export
 */
export const SearchFilterDtoTipologieEnum = {
    Corpo: 'CORPO',
    Canone: 'CANONE',
    Costo: 'COSTO',
    Consumo: 'CONSUMO',
    Contributo: 'CONTRIBUTO',
    Assets: 'ASSETS'
};
/**
 * Check if a given object implements the SearchFilterDto interface.
 */
export function instanceOfSearchFilterDto(value) {
    return true;
}
export function SearchFilterDtoFromJSON(json) {
    return SearchFilterDtoFromJSONTyped(json, false);
}
export function SearchFilterDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'stati': json['stati'] == null ? undefined : json['stati'],
        'tipologie': json['tipologie'] == null ? undefined : json['tipologie'],
        'clienti': json['clienti'] == null ? undefined : (json['clienti'].map(ClienteDtoFromJSON)),
        'businessManager': json['businessManager'] == null ? undefined : (json['businessManager'].map(BusinessManagerDtoFromJSON)),
        'projectManager': json['projectManager'] == null ? undefined : (json['projectManager'].map(ProjectUserDtoFromJSON)),
        'filter': json['filter'] == null ? undefined : (json['filter'].map(FilterDtoFromJSON)),
        'codiciRicavo': json['codiciRicavo'] == null ? undefined : (json['codiciRicavo'].map(CodiceCostoRicavoDtoFromJSON)),
        'codiciCosto': json['codiciCosto'] == null ? undefined : (json['codiciCosto'].map(CodiceCostoRicavoDtoFromJSON)),
        'companyIdList': json['companyIdList'] == null ? undefined : (json['companyIdList'].map(AziendaFromJSON)),
        'deliveryUnit': json['deliveryUnit'] == null ? undefined : (json['deliveryUnit'].map(ProjectOrganizationDtoFromJSON)),
        'businessUnit': json['businessUnit'] == null ? undefined : (json['businessUnit'].map(ProjectOrganizationDtoFromJSON)),
    };
}
export function SearchFilterDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'stati': value['stati'],
        'tipologie': value['tipologie'],
        'clienti': value['clienti'] == null ? undefined : (value['clienti'].map(ClienteDtoToJSON)),
        'businessManager': value['businessManager'] == null ? undefined : (value['businessManager'].map(BusinessManagerDtoToJSON)),
        'projectManager': value['projectManager'] == null ? undefined : (value['projectManager'].map(ProjectUserDtoToJSON)),
        'filter': value['filter'] == null ? undefined : (value['filter'].map(FilterDtoToJSON)),
        'codiciRicavo': value['codiciRicavo'] == null ? undefined : (value['codiciRicavo'].map(CodiceCostoRicavoDtoToJSON)),
        'codiciCosto': value['codiciCosto'] == null ? undefined : (value['codiciCosto'].map(CodiceCostoRicavoDtoToJSON)),
        'companyIdList': value['companyIdList'] == null ? undefined : (value['companyIdList'].map(AziendaToJSON)),
        'deliveryUnit': value['deliveryUnit'] == null ? undefined : (value['deliveryUnit'].map(ProjectOrganizationDtoToJSON)),
        'businessUnit': value['businessUnit'] == null ? undefined : (value['businessUnit'].map(ProjectOrganizationDtoToJSON)),
    };
}
