import {
  ContractOrderOutputDto,
  FatturaEmessaDto,
  FatturaEmessaPagedDto,
  FatturaEmessaSearchDto,
  FatturaRicevutaPagedDto,
  FatturaRicevutaSearchDto,
  ProjectLightInfoDto,
} from '@api/client';
import { createReducer } from '@reduxjs/toolkit';
import {
  getFattureEmesse,
  getFattureRicevute,
  getOrdiniAttivi,
  getProjectsForActiveBills,
  getProjectsForPassiveBills,
  saveFattureEmesseFiltersUsed,
  saveFattureRicevuteFiltersUsed,
} from './actions';

interface InitialState {
  fattureEmesse: FatturaEmessaPagedDto | undefined;
  fattureRicevute: FatturaRicevutaPagedDto | undefined;
  filtersEmesse: any;
  filtersEmesseUsed: FatturaEmessaSearchDto | undefined;
  filtersRicevute: any;
  filtersRicevuteUsed: FatturaRicevutaSearchDto | undefined;
  activeOrders: ContractOrderOutputDto[];
  projectsForActiveBills: ProjectLightInfoDto[];
  projectsForPassiveBills: ProjectLightInfoDto[];
}

const initialState: InitialState = {
  fattureEmesse: undefined,
  fattureRicevute: undefined,
  filtersEmesse: undefined,
  filtersEmesseUsed: undefined,
  filtersRicevute: undefined,
  filtersRicevuteUsed: undefined,
  activeOrders: [],
  projectsForActiveBills: [],
  projectsForPassiveBills: [],
};

export const fattureReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getFattureEmesse.fulfilled, (state, action) => {
      state.fattureEmesse = action.payload;
    })
    .addCase(getFattureRicevute.fulfilled, (state, action) => {
      state.fattureRicevute = action.payload;
    })
    .addCase(getOrdiniAttivi.fulfilled, (state, action) => {
      state.activeOrders = action.payload?.contractOrderOutputDtoList || [];
    })
    .addCase(getProjectsForActiveBills.fulfilled, (state, action) => {
      state.projectsForActiveBills = action.payload || [];
    })
    .addCase(getProjectsForPassiveBills.fulfilled, (state, action) => {
      state.projectsForPassiveBills = action.payload || [];
    })
    .addCase(saveFattureEmesseFiltersUsed, (state, action) => {
      state.filtersEmesseUsed = action.payload;
    })
    .addCase(saveFattureRicevuteFiltersUsed, (state, action) => {
      state.filtersRicevuteUsed = action.payload;
    });
});
