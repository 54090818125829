import { SetStateAction } from 'react';
import { RevenueAllocation, RevenueEnum } from '../../_models/revenueAllocation';
import { useAppDispatch, useAppSelector } from '../../../../../../core/store/hooks';
import { currentProjectRevenueSelector, currentProjectSelector } from '../../../_redux/selectors';
import { RevenueMonthDto } from '@api/client';
import { saveRevenueProgress } from '../../_redux/actions';

const useProgressManagement = (
  revenueAllocations: RevenueAllocation[] | null,
  setRevenueAllocations: React.Dispatch<SetStateAction<RevenueAllocation[] | null>>
) => {
  const dispatch = useAppDispatch();
  const currentProjectRevenue = useAppSelector(currentProjectRevenueSelector) || 0;
  const currentProject = useAppSelector(currentProjectSelector);

  const handleChangeAllocation = (value: number | null, month: number | undefined, year: number | undefined) => {
    // update the revenueList with the same revenueEnum, month and year, and ther recalculate the allocation percentage and cumulative percentage
    let newAllocations =
      revenueAllocations?.map((el) => {
        if (el.revenueEnum === RevenueEnum.RevenuePerMonthPercentage) {
          return {
            ...el,
            revenueList: el.revenueList.map((el) => {
              if (el.month === month && el.year === year) {
                return {
                  ...el,
                  amount: value || undefined,
                };
              }
              return el;
            }),
          };
        } else if (el.revenueEnum === RevenueEnum.RevenuePerMonth) {
          const updatedAllocations = {
            ...el,
            revenueList: el.revenueList.map((el) => {
              if (el.month === month && el.year === year) {
                return {
                  ...el,
                  amount: ((value || 0) * currentProjectRevenue) / 100,
                };
              }
              return el;
            }),
          };
          dispatch(saveRevenueProgress(updatedAllocations.revenueList));
          return updatedAllocations;
        } else if (el.revenueEnum === RevenueEnum.CumulativeRevenuePercentage) {
          return {
            ...el,
            revenueList: el.revenueList.map((el, index) => {
              return {
                ...el,
                amount: revenueAllocations
                  ?.find((el) => el.revenueEnum === RevenueEnum.RevenuePerMonthPercentage)
                  ?.revenueList.slice(0, index + 1)
                  .reduce((acc, curr) => acc + (curr.amount || 0), 0),
              };
            }),
          };
        } else if (el.revenueEnum === RevenueEnum.MarginPerMonthPercentage) {
          return {
            ...el,
            revenueList: el.revenueList.map((el) => {
              if (el.month === month && el.year === year) {
                return {
                  ...el,
                  amount: value ? calculateMargin(value, el) : 0,
                };
              }
              return el;
            }),
          };
        }
        return el;
      }) || [];

    setRevenueAllocations(newAllocations);
  };

  const calculateMargin = (value: number | null, revenue: RevenueMonthDto) => {
    const revenueMonth = ((value || 0) * currentProjectRevenue) / 100;
    const costMonth = getCostForMonthYear(revenue);
    const margin = (revenueMonth - costMonth) / revenueMonth;
    return margin * 100;
  };

  const getCostForMonthYear = (revenue: RevenueMonthDto) => {
    const cost = currentProject?.revenuePlan?.find(
      (el) => el.year === revenue.year && el.month === revenue.month
    )?.actualCost;
    return cost || 0;
  };

  return {
    handleChangeAllocation,
  };
};

export default useProgressManagement;
