/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PagingDtoFromJSON, PagingDtoToJSON, } from './PagingDto';
/**
 * Check if a given object implements the FatturaEmessaSearchDto interface.
 */
export function instanceOfFatturaEmessaSearchDto(value) {
    return true;
}
export function FatturaEmessaSearchDtoFromJSON(json) {
    return FatturaEmessaSearchDtoFromJSONTyped(json, false);
}
export function FatturaEmessaSearchDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'idFattura': json['idFattura'] == null ? undefined : json['idFattura'],
        'importoMin': json['importoMin'] == null ? undefined : json['importoMin'],
        'importoMax': json['importoMax'] == null ? undefined : json['importoMax'],
        'tipoFattura': json['tipoFattura'] == null ? undefined : json['tipoFattura'],
        'cliente': json['cliente'] == null ? undefined : json['cliente'],
        'dataFatturaDa': json['dataFatturaDa'] == null ? undefined : (new Date(json['dataFatturaDa'])),
        'dataFatturaA': json['dataFatturaA'] == null ? undefined : (new Date(json['dataFatturaA'])),
        'statoFattura': json['statoFattura'] == null ? undefined : json['statoFattura'],
        'numeroOrdine': json['numeroOrdine'] == null ? undefined : json['numeroOrdine'],
        'denominazioneAzienda': json['denominazioneAzienda'] == null ? undefined : json['denominazioneAzienda'],
        'excludefattureAssociate': json['excludefattureAssociate'] == null ? undefined : json['excludefattureAssociate'],
        'paging': json['paging'] == null ? undefined : PagingDtoFromJSON(json['paging']),
    };
}
export function FatturaEmessaSearchDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'idFattura': value['idFattura'],
        'importoMin': value['importoMin'],
        'importoMax': value['importoMax'],
        'tipoFattura': value['tipoFattura'],
        'cliente': value['cliente'],
        'dataFatturaDa': value['dataFatturaDa'] == null ? undefined : ((value['dataFatturaDa']).toISOString().substring(0, 10)),
        'dataFatturaA': value['dataFatturaA'] == null ? undefined : ((value['dataFatturaA']).toISOString().substring(0, 10)),
        'statoFattura': value['statoFattura'],
        'numeroOrdine': value['numeroOrdine'],
        'denominazioneAzienda': value['denominazioneAzienda'],
        'excludefattureAssociate': value['excludefattureAssociate'],
        'paging': PagingDtoToJSON(value['paging']),
    };
}
