import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Table } from 'react-bootstrap';
import { formatCurrency, getFormattedDateHeader } from '../../../../shared/utils/common.utils';
import '../people-table/styles.scss';
import { useAppSelector } from '../../../../core/store/hooks';
import { dashboardOverbookingUsersSelector, dashboardUnderbookingUsersSelector } from '../_redux/selectors';
import { DashboardBookingDto, ProjectDto } from '@api/client';
import NoData from '../../../../shared/design-system/components/no-data-box';
import usePagination from '../../../../core/hooks/usePagination';
import { StorageKeysEnum } from '../../../../enums/storageKeys.enum';
import AppPaginator from '../../../../shared/design-system/components/app-paginator';
import AppAccordion from '../../../../shared/design-system/components/app-accordion';
import FiltersDashboardPeople, { filtersDashboardPeopleData } from '../_components/filters-dashboard-people';
import useFormDashboardPeople from '../../../../shared/design-system/components/app-accordion/_hooks/useFormDashboardPeople';
import { PathEnum } from '../../../../core/router/path.enum';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { DashboardUserTables } from '../../../../enums/dashboard.enum';
import AppCard from '../../../../shared/design-system/components/app-card';
import useGetBookingTotals from '../_hooks/booking-hooks/useGetBookingTotals';
import { Totals } from '../_hooks/projects-hooks/useGetProjectTotals';
import useGetBookingResources from '../_hooks/booking-hooks/useGetBookingResources';
import { LocationFilterEnum } from '../../../../enums/global.enum';
import { StatusBadgeData } from '../../../../enums/status';

interface Props {
  table: DashboardUserTables;
}

const BookingTable: React.FC<Props> = ({ table }) => {
  const { t } = useTranslation();
  const [expandedRows, setExpandedRows] = useState<any>({});
  const bookingUsers = useAppSelector(
    table === DashboardUserTables.UNDERBOOKING ? dashboardUnderbookingUsersSelector : dashboardOverbookingUsersSelector
  );

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { users, allMonths, consolidatedMonths } = useGetBookingResources(table, bookingUsers);
  const tableRef = useRef<HTMLTableElement>(null);

  const { onSubmit, onReset, chipsFormData, setChipsFormData, reset, filterParams } = useFormDashboardPeople(
    filtersDashboardPeopleData,
    table
  );

  const {
    pageNumber: pageNumberUser,
    setPageNumber: setPageNumberUser,
    totalPages: totalPagesUser,
    visibleItems: visibleUsers,
  } = usePagination(users?.dashboardBookingDtoList || [], 50);

  const [visUsers, setVisUsers] = useState(visibleUsers);

  const { totals } = useGetBookingTotals(bookingUsers?.personCount || 0, bookingUsers?.totalNfte || 0);

  useEffect(() => {
    if (visibleUsers) {
      setVisUsers(visibleUsers);
    }
  }, [visibleUsers]);

  const handleReset = () => {
    onReset();
    chipsFormData && setChipsFormData([]);
  };

  const handleRowClick = (userId: any) => {
    console.log(expandedRows);
    setExpandedRows((prevExpandedRows: any) => ({
      ...prevExpandedRows,
      [userId]: !prevExpandedRows[userId],
    }));
  };

  useEffect(() => {
    const consolidatedMonthsLength = consolidatedMonths.length;
    scrollToRight(consolidatedMonthsLength * 70);
  }, [users]);

  const scrollToRight = (px: number) => {
    if (tableRef.current) {
      tableRef.current.scrollBy({
        top: 0,
        left: px,
        behavior: 'smooth',
      });
    }
  };

  return (
    <>
      <AppAccordion
        formData={filtersDashboardPeopleData}
        location={LocationFilterEnum.DASHBOARD_RESOURCES}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        chipsFormData={chipsFormData}
        setChipsFormData={setChipsFormData}
        reset={reset}
      >
        <FiltersDashboardPeople onSubmit={onSubmit} handleReset={handleReset} />
      </AppAccordion>
      {!users || users?.dashboardBookingDtoList?.length === 0 ? (
        <div className="mt-3">
          <NoData msg={t('dashboard.resources.no-resources')} />
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-start">
            {totals.map((field: Totals, index) => (
              <AppCard
                title={field.label}
                className={`mt-3 me-3 ${field.border}`}
                color={field.color}
                style={{ minWidth: '150px' }}
              >
                <Col sm={'auto'} key={field.value + index} className="g-0">
                  <div className="d-flex align-items-baseline">
                    <div className="big-value">
                      {field.value !== null && field.value !== undefined ? (
                        <>
                          <span className={`mr-1 fw-bold`} style={{ color: field.color }}>
                            {field.value.split('/')[0]}
                          </span>{' '}
                          {field.value.includes('/') && <span>/</span>}
                          <span className="fs-8" style={{ color: field.color }}>
                            {' '}
                            {field.value.split('/')[1]}
                          </span>
                        </>
                      ) : (
                        '-'
                      )}
                    </div>
                    {field.span && field.value !== null && field.value !== undefined && field.value !== '-' && (
                      <span className="text-xs" style={{ color: field.color }}>
                        {field.span}
                      </span>
                    )}
                  </div>
                  {/* <div className="head notification-header">{field.label}</div> */}
                </Col>
              </AppCard>
            ))}
          </div>
          <p className="d-flex justify-content-end">{t('dashboard.resources.values-legend')}</p>
          <div className="d-flex" style={{ overflowX: 'auto' }} ref={tableRef}>
            <Table bordered className="revenue-table sticky-table test-table mt-0 mb-0">
              <thead style={{ height: '80px' }}>
                <tr>
                  <th></th>
                  <th style={{ minWidth: '350px' }}>{t('dashboard.resources.table-titles.name')}</th>
                  {Object.values(expandedRows).some((x) => x) && (
                    <th>{t('dashboard.resources.table-titles.status')}</th>
                  )}
                  <th style={{ minWidth: '80px' }}>
                    <span>{t('dashboard.resources.table-titles.rend')}</span>
                    <Tooltip title={t('project-detail.current-month-rendicontations-tooltip')} color="white">
                      <i className="fas fa-circle-info text-blue-links ps-2" />
                    </Tooltip>
                  </th>
                </tr>
              </thead>
              <tbody>
                {visUsers?.map((user: DashboardBookingDto, index: number) => (
                  <React.Fragment key={`${user.nomeUtente}${index}`}>
                    <tr>
                      <td className="text-center">
                        <div className="container-dashboard">
                          <Button
                            className="btn-sm btn-icon p-0 border-none"
                            onClick={() => handleRowClick(user.nomeUtente)}
                          >
                            <i className="fst-normal fw-bold fs-6 text-blue-links">
                              {expandedRows[user.nomeUtente!] ? '-' : '+'}
                            </i>
                          </Button>
                        </div>
                      </td>
                      <td colSpan={Object.values(expandedRows).some((x) => x) ? 2 : 1}>
                        <div className="container-dashboard">{user.nomeUtente}</div>
                      </td>
                      <td>
                        <div style={{ minWidth: '80px' }}>
                          {formatCurrency(user.rendicontazioniMeseTotali?.totaleGiorni)}
                        </div>
                      </td>
                    </tr>
                    {expandedRows[user.nomeUtente!] &&
                      user.projects?.map((project: ProjectDto) => (
                        <tr key={project.projectName} className="project-row">
                          <td></td>
                          <td className="align-top">
                            <div className="container-dashboard project-name">
                              <a
                                href={`${window.location.origin}${window.location.pathname}#/${PathEnum.PRIVATE}/${PathEnum.PROGETTI}/${project?.projectId}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-links"
                              >
                                {project.projectName}
                              </a>
                            </div>
                          </td>
                          <td>
                            <div style={{ whiteSpace: 'nowrap' }}>
                              {StatusBadgeData.find((x) => x.name === project.stato)?.label || project.stato}
                            </div>
                          </td>
                          <td>
                            <div>{formatCurrency(project.rendicontazioneMese?.totaleGiorni)}</div>
                          </td>
                        </tr>
                      ))}
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
            <Table bordered className="revenue-table overbooking-table mt-0 mb-0">
              <thead>
                <tr>
                  {allMonths.map((date) => (
                    <th key={`${date.month}-${date.year}`} className="text-center" colSpan={5}>
                      {getFormattedDateHeader(date.month, date.year)}
                    </th>
                  ))}
                </tr>
                <tr>
                  {allMonths.map((date) => (
                    <React.Fragment key={`${date.month}-${date.year}`}>
                      <th id="fte">{t('dashboard.resources.table-titles.actual-forecast-fte')}</th>
                      <th id="md">{t('dashboard.resources.table-titles.actual-forecast-md')}</th>
                      <th id="vacation">{t('dashboard.resources.table-titles.vacation')}</th>
                      <th id="sick-leave">{t('dashboard.resources.table-titles.sickness')}</th>
                      <th id="other">{t('dashboard.resources.table-titles.other')}</th>
                    </React.Fragment>
                  ))}
                </tr>
              </thead>
              <tbody>
                {visUsers?.map((user: DashboardBookingDto, index: number) => (
                  <React.Fragment key={`${user.nomeUtente}${index}`}>
                    <tr>
                      {user.dettagliMeseAnno?.map((days) => (
                        <>
                          <td key={`FTE${days.mese}-${days.anno}`} id="fte-cell">
                            <div className="container-dashboard">
                              {days.stfAfFteTot !== undefined ? formatCurrency(days.stfAfFteTot) : '-'}
                            </div>
                          </td>
                          <td key={`GU${days.mese}-${days.anno}`} id="md-cell">
                            <div className="container-dashboard">
                              {days.stfAfGuTot !== undefined ? formatCurrency(days.stfAfGuTot) : '-'}
                            </div>
                          </td>
                          <td key={`Vac${days.mese}-${days.anno}`}>
                            <div className="container-dashboard">
                              {days.stfAfGuFerieTot !== undefined ? formatCurrency(days.stfAfGuFerieTot) : '-'}
                            </div>
                          </td>
                          <td key={`Sic${days.mese}-${days.anno}`}>
                            <div className="container-dashboard">
                              {days.sftAfGuMalattia !== undefined ? formatCurrency(days.sftAfGuMalattia) : '-'}
                            </div>
                          </td>
                          <td key={`Oth${days.mese}-${days.anno}`}>
                            <div className="container-dashboard">
                              {days.sftAfGuOthAssenza !== undefined ? formatCurrency(days.sftAfGuOthAssenza) : '-'}
                            </div>
                          </td>
                        </>
                      ))}
                    </tr>
                    {expandedRows[user.nomeUtente!] &&
                      user.projects?.map((project: ProjectDto, index: number) => (
                        <tr key={`${project.projectName}${index}`} className="project-row">
                          {project.projectDaysDtoList?.map((days) => (
                            <td key={`${days.month}-${days.year}`} className="align-top" colSpan={5}>
                              <div className="container-dashboard" style={{ height: 'fit-content' }}>
                                {formatCurrency(days?.daysActualForecast)}
                              </div>
                            </td>
                          ))}
                        </tr>
                      ))}
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          </div>
          {visibleUsers?.length > 0 ? (
            <AppPaginator
              keyPaginator={
                table === DashboardUserTables.UNDERBOOKING
                  ? StorageKeysEnum.CURRENT_PAGE_DASHBOARD_UNDERBOOKING
                  : StorageKeysEnum.CURRENT_PAGE_DASHBOARD_OVERBOOKING
              }
              currentPage={pageNumberUser}
              totalPages={totalPagesUser}
              totalElements={users?.dashboardBookingDtoList?.length || 0}
              pageSize={50}
              onPageChange={setPageNumberUser}
            />
          ) : (
            ''
          )}
        </>
      )}
    </>
  );
};

export default BookingTable;
