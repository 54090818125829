import {
  CompetenzeProgettoDto,
  Configuration,
  ControllerProjectApi,
  ControllerStaffApi,
  ControllerUserApi,
  DeleteMonthRequest,
  DeleteStaffRequest,
  DetailContractExternalCostDto,
  ExternalCostDto,
  GetExternalCostsByProjectRequest,
  InsertDateAndRoleRequest,
  ListCostTypeDto,
  PlanningControllerApi,
  RevenueMonthDto,
  SearchRuoliResponseDto,
  SearchUserResponseDto,
  SearchUsersRequest,
  StaffExtendedDto,
  UpdateCostsInputDto,
  UpdateCostsRequest,
} from '@api/client';
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import getConfiguration, { errorHandler } from '../../../../../core/store/config';
import { ThunkApiConfig } from '../../../../../core/store';

enum ProjectDetailAction {
  getRoles = '[ProjectDetail] Get Roles',
  getCosts = '[ProjectDetail] Get Costs',
  insertStaff = '[ProjectDetail] Insert Staff',
  updateStaff = '[ProjectDetail] Update Staff',
  getUsers = '[ProjectDetail] Get Users',
  sendPlanning = '[ProjectDetail] Send Planning',
  replanning = '[ProjectDetail] Replanning',
  saveTeamData = '[ProjectDetail] Save Team Data',
  sateCostsData = '[ProjectDetail] Save Costs Data',
  resetTeamAndCostsData = '[ProjectDetail] Reset Team And Costs Data',
  deleteCollaborator = '[ProjectDetail] Delete Collaborator',
  deleteMonths = '[ProjectDetail] Delete Months',
  closeRendicontazione = '[ProjectDetail] Close Rendicontazione',
  saveRevenuesToBeConsolidated = '[ProjectDetail] Save Revenues To Be Consolidated',
  getCompetenzeComplessive = '[ProjectDetail] Get Competenze Complessive',
  getCompetenzeAnnoCorrente = '[ProjectDetail] Get Competenze Anno Corrente',
  getAssociatedContracts = '[ProjectDetail] Get Associated Contracts',
  saveRevenueProgress = '[ProjectDetail] Save Revenue Progress',
}

export const getRoles = createAsyncThunk<SearchRuoliResponseDto | undefined, void, ThunkApiConfig>(
  ProjectDetailAction.getRoles,
  async (_, { dispatch, extra, getState }) => {
    try {
      return await new ControllerStaffApi(getConfiguration(Configuration, extra, getState)).ruoliSearch();
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const getCosts = createAsyncThunk<ListCostTypeDto | undefined, void, ThunkApiConfig>(
  ProjectDetailAction.getCosts,
  async (_, { dispatch, extra, getState }) => {
    try {
      return await new ControllerStaffApi(getConfiguration(Configuration, extra, getState)).costTypeList();
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const insertStaff = createAsyncThunk<string | void, InsertDateAndRoleRequest, ThunkApiConfig>(
  ProjectDetailAction.insertStaff,
  async (staff, { dispatch, extra, getState }) => {
    try {
      await new ControllerStaffApi(getConfiguration(Configuration, extra, getState)).insertDateAndRole(staff);
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const updateStaff = createAsyncThunk<UpdateCostsInputDto | undefined, UpdateCostsRequest, ThunkApiConfig>(
  ProjectDetailAction.updateStaff,
  async (staff, { dispatch, extra, getState }) => {
    try {
      return await new PlanningControllerApi(getConfiguration(Configuration, extra, getState)).updateCosts(staff);
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const getUsers = createAsyncThunk<SearchUserResponseDto | undefined, SearchUsersRequest, ThunkApiConfig>(
  ProjectDetailAction.getUsers,
  async (request, { dispatch, extra, getState }) => {
    try {
      return await new ControllerUserApi(getConfiguration(Configuration, extra, getState)).searchUsers(request);
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const sendPlanning = createAsyncThunk<string | undefined, number, ThunkApiConfig>(
  ProjectDetailAction.sendPlanning,
  async (projectId, { dispatch, extra, getState }) => {
    try {
      return await new PlanningControllerApi(getConfiguration(Configuration, extra, getState)).inviaPlanning({
        projectId,
      });
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const replanning = createAsyncThunk<string | undefined, number, ThunkApiConfig>(
  ProjectDetailAction.replanning,
  async (projectId, { dispatch, extra, getState }) => {
    try {
      return await new PlanningControllerApi(getConfiguration(Configuration, extra, getState)).ripianifica({
        projectId,
      });
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const deleteStaff = createAsyncThunk<string | undefined, DeleteStaffRequest, ThunkApiConfig>(
  ProjectDetailAction.deleteCollaborator,
  async (payload, { dispatch, extra, getState }) => {
    try {
      return await new ControllerStaffApi(getConfiguration(Configuration, extra, getState)).deleteStaff(payload);
    } catch (e) {
      // errorHandler(e, dispatch, getState);
    }
  }
);

export const deleteMonths = createAsyncThunk<string | undefined, DeleteMonthRequest, ThunkApiConfig>(
  ProjectDetailAction.deleteMonths,
  async (payload, { dispatch, extra, getState }) => {
    try {
      return await new ControllerStaffApi(getConfiguration(Configuration, extra, getState)).deleteMonth(payload);
    } catch (e) {
      // errorHandler(e, dispatch, getState);
    }
  }
);

export const closeRendicontazione = createAsyncThunk<void | undefined, number, ThunkApiConfig>(
  ProjectDetailAction.closeRendicontazione,
  async (projectId, { dispatch, extra, getState }) => {
    try {
      await new ControllerProjectApi(getConfiguration(Configuration, extra, getState)).closeRendicontazione({
        projectId,
      });
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const getCompetenzeComplessive = createAsyncThunk<CompetenzeProgettoDto | undefined, number, ThunkApiConfig>(
  ProjectDetailAction.getCompetenzeComplessive,
  async (projectId, { dispatch, extra, getState }) => {
    try {
      return await new ControllerProjectApi(getConfiguration(Configuration, extra, getState)).getCompetenzeComplessive({
        projectId,
      });
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const getCompetenzeAnnoCorrente = createAsyncThunk<CompetenzeProgettoDto | undefined, number, ThunkApiConfig>(
  ProjectDetailAction.getCompetenzeAnnoCorrente,
  async (projectId, { dispatch, extra, getState }) => {
    try {
      return await new ControllerProjectApi(getConfiguration(Configuration, extra, getState)).getCompetenzeAnnoCorrente(
        {
          projectId,
        }
      );
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const getAssociatedContracts = createAsyncThunk<
  DetailContractExternalCostDto[] | undefined,
  number,
  ThunkApiConfig
>(ProjectDetailAction.getAssociatedContracts, async (projectId, { dispatch, extra, getState }) => {
  try {
    return await new ControllerProjectApi(getConfiguration(Configuration, extra, getState)).getExternalCostsByProject({
      projectId,
    });
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const saveTeamData = createAction<Partial<StaffExtendedDto>[] | null | undefined>(
  ProjectDetailAction.saveTeamData
);

export const saveCostsData = createAction<ExternalCostDto[] | null | undefined>(ProjectDetailAction.sateCostsData);

export const resetTeamAndCostsData = createAction(ProjectDetailAction.resetTeamAndCostsData);

export const saveRevenuesToBeConsolidated = createAction<number | undefined>(
  ProjectDetailAction.saveRevenuesToBeConsolidated
);

export const saveRevenueProgress = createAction<RevenueMonthDto[] | undefined>(ProjectDetailAction.saveRevenueProgress);
