import {
  AggregatedStrutturaInfoDto,
  Configuration,
  ControllerDedicatoAFunzioniSoloPerAmministratoriApi,
  ControllerProjectApi,
  ControllerTecnicoApi,
  CreateStrutturaUtenteFunzioneRuoloRequest,
  CreateUpdateProjectQuoteDto,
  DettagliProgettoDto,
  DimAlberoCommercialeDto,
  DimAlberoCommercialeL1Dto,
  DimAlberoDeliveryDto,
  DimAlberoDeliveryL1Dto,
  GetAlbBusinessNewRequest,
  GetAlbBusinessRequest,
  GetAlbDeliveryNewRequest,
  GetAlbDeliveryRequest,
  ProjectDataUpdateRequest,
  ProjectPastDataDatail,
  SearchFilterDto,
  StrutturaFunzioneOutputDto,
  StrutturaOutputDto,
  StrutturaRuoloOutputDto,
  StrutturaUtenteFunzioneRuoloInputDto,
  StrutturaUtenteFunzioneRuoloOutputDto,
  TipoStrutturaOutputDto,
  UpdateHrCostForProjectRequest,
  UpdateProjectAndQuoteAdminRequest,
  UpdateProjectWithRevenutAndCostsByIdRequest,
} from '@api/client';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiConfig } from '../../../../core/store';
import getConfiguration, { errorHandler } from '../../../../core/store/config';

enum CrudActions {
  crudGetProject = '[Crud] Get Project',
  crudReset = '[Crud] Reset',
  crudUpdateProject = '[Crud] Update Project',
  crudCreateProject = '[Crud] Create Project',
  crudGetRevenueCostsProject = '[Crud] Get Revenue Costs Project',
  crudUpdateRevenueCostsProject = '[Crud] Update Revenue Costs Project',
  crudUpdateHrCosts = '[Crud] Update Hr Costs',
  crudEditProjectDelivery = '[Crud] Edit Project Delivery',
  crudGetStructures = '[Crud] Get Structures',
  crudGetStructureTypes = '[Crud] Get Structure Types',
  crudGetStructureRoles = '[Crud] Get Structure Roles',
  crudGetStructureFunctions = '[Crud] Get Structure Functions',
  crudCreateUserStructureFuncionRole = '[Crud] Create User Structure Function Role',
  getCrudFilters = '[Crud] Get Filters',
  crudCreateIntranetProject = '[Crud] Create Intranet Project',
  crudActivateIntranetProject = '[Crud] Activate Intranet Project',
  crudGetAlberatyraDelivery = '[Crud] Get Alberatyra Delivery',
  crudGetAlberaturaBusiness = '[Crud] Get Alberatura Business',
  crudGetAlberatyraDeliveryNew = '[Crud] Get Alberatyra Delivery New',
  crudGetAlberaturaBusinessNew = '[Crud] Get Alberatura Business New',
  crudGetStructureDetail = '[Crud] Get Structure Detail',
  crudDeleteStructure = '[Crud] Delete Structure',
}

export const crudGetProject = createAsyncThunk<CreateUpdateProjectQuoteDto | undefined, number, ThunkApiConfig>(
  CrudActions.crudGetProject,
  async (projectId, { dispatch, extra, getState }) => {
    try {
      return await new ControllerDedicatoAFunzioniSoloPerAmministratoriApi(
        getConfiguration(Configuration, extra, getState)
      ).getProjectAndQuote({
        projectId,
      });
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const crudUpdateProject = createAsyncThunk<
  CreateUpdateProjectQuoteDto | undefined,
  CreateUpdateProjectQuoteDto,
  ThunkApiConfig
>(CrudActions.crudUpdateProject, async (projectWrapper, { dispatch, extra, getState }) => {
  try {
    const payload: UpdateProjectAndQuoteAdminRequest = {
      projectId: projectWrapper.createUpdateProjectDto?.id as number,
      createUpdateProjectQuoteDto: projectWrapper,
    };
    return await new ControllerDedicatoAFunzioniSoloPerAmministratoriApi(
      getConfiguration(Configuration, extra, getState)
    ).updateProjectAndQuoteAdmin(payload);
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const crudCreateProject = createAsyncThunk<
  CreateUpdateProjectQuoteDto | undefined,
  CreateUpdateProjectQuoteDto,
  ThunkApiConfig
>(CrudActions.crudCreateProject, async (projectWrapper, { dispatch, extra, getState }) => {
  try {
    return await new ControllerDedicatoAFunzioniSoloPerAmministratoriApi(
      getConfiguration(Configuration, extra, getState)
    ).createProjectAndQuoteAdmin({
      createUpdateProjectQuoteDto: projectWrapper,
    });
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const crudGetRevenueCostsProject = createAsyncThunk<ProjectPastDataDatail | undefined, number, ThunkApiConfig>(
  CrudActions.crudGetRevenueCostsProject,
  async (projectId, { dispatch, extra, getState }) => {
    try {
      return await new ControllerTecnicoApi(
        getConfiguration(Configuration, extra, getState)
      ).getProjectWithRevenutAndCostsById({
        projectId,
      });
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const crudUpdateRevenueCostsProject = createAsyncThunk<
  ProjectPastDataDatail | undefined,
  UpdateProjectWithRevenutAndCostsByIdRequest,
  ThunkApiConfig
>(CrudActions.crudUpdateRevenueCostsProject, async (payload, { dispatch, extra, getState }) => {
  try {
    return await new ControllerDedicatoAFunzioniSoloPerAmministratoriApi(
      getConfiguration(Configuration, extra, getState)
    ).updateProjectWithRevenutAndOtherCostsCostsById(payload);
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const crudUpdateHrCosts = createAsyncThunk<
  ProjectPastDataDatail | undefined,
  UpdateHrCostForProjectRequest,
  ThunkApiConfig
>(CrudActions.crudUpdateHrCosts, async (payload, { dispatch, extra, getState }) => {
  try {
    return await new ControllerDedicatoAFunzioniSoloPerAmministratoriApi(
      getConfiguration(Configuration, extra, getState)
    ).updateReportingCostForProject(payload);
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const crudEditProjectDelivery = createAsyncThunk<
  DettagliProgettoDto | undefined,
  DettagliProgettoDto,
  ThunkApiConfig
>(CrudActions.crudEditProjectDelivery, async (data, { dispatch, extra, getState }) => {
  try {
    const payload: ProjectDataUpdateRequest = {
      dettagliProgettoDto: data,
    };
    return await new ControllerProjectApi(getConfiguration(Configuration, extra, getState)).projectDataUpdate(payload);
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const crudGetStructures = createAsyncThunk<StrutturaOutputDto[] | undefined, void, ThunkApiConfig>(
  CrudActions.crudGetStructures,
  async (_, { dispatch, extra, getState }) => {
    try {
      return await new ControllerTecnicoApi(getConfiguration(Configuration, extra, getState)).getAllStrutture();
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const crudGetStructuresTypes = createAsyncThunk<TipoStrutturaOutputDto[] | undefined, void, ThunkApiConfig>(
  CrudActions.crudGetStructureTypes,
  async (_, { dispatch, extra, getState }) => {
    try {
      return await new ControllerTecnicoApi(getConfiguration(Configuration, extra, getState)).getAllTipiStruttura();
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const crudGetStructureRoles = createAsyncThunk<StrutturaRuoloOutputDto[] | undefined, void, ThunkApiConfig>(
  CrudActions.crudGetStructureRoles,
  async (_, { dispatch, extra, getState }) => {
    try {
      return await new ControllerTecnicoApi(getConfiguration(Configuration, extra, getState)).getAllStrutturaRuolo();
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const crudGetStructureFunctions = createAsyncThunk<
  StrutturaFunzioneOutputDto[] | undefined,
  void,
  ThunkApiConfig
>(CrudActions.crudGetStructureFunctions, async (_, { dispatch, extra, getState }) => {
  try {
    return await new ControllerTecnicoApi(getConfiguration(Configuration, extra, getState)).getAllStruttureFunzione();
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const crudCreateUserStructureFuncionRole = createAsyncThunk<
  StrutturaUtenteFunzioneRuoloOutputDto | undefined,
  StrutturaUtenteFunzioneRuoloInputDto,
  ThunkApiConfig
>(CrudActions.crudCreateUserStructureFuncionRole, async (data, { dispatch, extra, getState }) => {
  try {
    const payload: CreateStrutturaUtenteFunzioneRuoloRequest = {
      strutturaUtenteFunzioneRuoloInputDto: data,
    };
    return await new ControllerTecnicoApi(
      getConfiguration(Configuration, extra, getState)
    ).createStrutturaUtenteFunzioneRuolo(payload);
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const getCrudFilters = createAsyncThunk<SearchFilterDto | undefined, void, ThunkApiConfig>(
  CrudActions.getCrudFilters,
  async (_, { dispatch, extra, getState }) => {
    try {
      return await new ControllerTecnicoApi(getConfiguration(Configuration, extra, getState)).projectFields();
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const crudCreateIntranetProject = createAsyncThunk<string | undefined, number, ThunkApiConfig>(
  CrudActions.crudCreateIntranetProject,
  async (projectId, { dispatch, extra, getState }) => {
    try {
      return await new ControllerDedicatoAFunzioniSoloPerAmministratoriApi(
        getConfiguration(Configuration, extra, getState)
      ).adminCreateIntranetProject({
        projectId,
      });
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const crudActivateIntranetProject = createAsyncThunk<string | undefined, number, ThunkApiConfig>(
  CrudActions.crudActivateIntranetProject,
  async (projectId, { dispatch, extra, getState }) => {
    try {
      return await new ControllerDedicatoAFunzioniSoloPerAmministratoriApi(
        getConfiguration(Configuration, extra, getState)
      ).activateIntranetProject({
        projectId,
      });
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const crudGetAlberaturaDelivery = createAsyncThunk<
  Array<DimAlberoDeliveryDto> | undefined,
  GetAlbDeliveryRequest,
  ThunkApiConfig
>(CrudActions.crudGetAlberatyraDelivery, async (payload, { dispatch, extra, getState }) => {
  try {
    return await new ControllerTecnicoApi(getConfiguration(Configuration, extra, getState)).getAlbDelivery(payload);
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const crudGetAlberaturaBusiness = createAsyncThunk<
  Array<DimAlberoCommercialeDto> | undefined,
  GetAlbBusinessRequest,
  ThunkApiConfig
>(CrudActions.crudGetAlberaturaBusiness, async (payload, { dispatch, extra, getState }) => {
  try {
    return await new ControllerTecnicoApi(getConfiguration(Configuration, extra, getState)).getAlbBusiness(payload);
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const crudGetAlberaturaDeliveryNew = createAsyncThunk<
  Array<DimAlberoDeliveryL1Dto> | undefined,
  GetAlbDeliveryNewRequest,
  ThunkApiConfig
>(CrudActions.crudGetAlberatyraDeliveryNew, async (payload, { dispatch, extra, getState }) => {
  try {
    return await new ControllerTecnicoApi(getConfiguration(Configuration, extra, getState)).getAlbDeliveryNew(payload);
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const crudGetAlberaturaBusinessNew = createAsyncThunk<
  Array<DimAlberoCommercialeL1Dto> | undefined,
  GetAlbBusinessNewRequest,
  ThunkApiConfig
>(CrudActions.crudGetAlberaturaBusinessNew, async (payload, { dispatch, extra, getState }) => {
  try {
    return await new ControllerTecnicoApi(getConfiguration(Configuration, extra, getState)).getAlbBusinessNew(payload);
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const crudGetStructureDetail = createAsyncThunk<AggregatedStrutturaInfoDto | undefined, number, ThunkApiConfig>(
  CrudActions.crudGetStructureDetail,
  async (id, { dispatch, extra, getState }) => {
    try {
      return await new ControllerTecnicoApi(getConfiguration(Configuration, extra, getState)).getStrutturaInfo({
        idFunzione: id,
      });
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const crudDeleteStructure = createAsyncThunk<void | undefined, number, ThunkApiConfig>(
  CrudActions.crudDeleteStructure,
  async (id, { dispatch, extra, getState }) => {
    try {
      return await new ControllerTecnicoApi(
        getConfiguration(Configuration, extra, getState)
      ).deleteStrutturaUtenteFunzioneRuolo({
        idFunzione: id,
      });
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const crudReset = createAction(CrudActions.crudReset);
