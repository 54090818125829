/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * @export
 */
export const LoggedUserDtoRolesEnum = {
    Am: 'AM',
    Pl: 'PL',
    Wfm: 'WFM',
    Cdg: 'CDG',
    Cp: 'CP',
    Ca: 'CA',
    Dm: 'DM',
    SuperAdmin: 'SUPER_ADMIN'
};
/**
 * @export
 */
export const LoggedUserDtoLanguageEnum = {
    It: 'IT',
    En: 'EN'
};
/**
 * Check if a given object implements the LoggedUserDto interface.
 */
export function instanceOfLoggedUserDto(value) {
    return true;
}
export function LoggedUserDtoFromJSON(json) {
    return LoggedUserDtoFromJSONTyped(json, false);
}
export function LoggedUserDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'userId': json['userId'] == null ? undefined : json['userId'],
        'name': json['name'] == null ? undefined : json['name'],
        'email': json['email'] == null ? undefined : json['email'],
        'roles': json['roles'] == null ? undefined : json['roles'],
        'language': json['language'] == null ? undefined : json['language'],
        'canDefineDesignatedUserForHimself': json['canDefineDesignatedUserForHimself'] == null ? undefined : json['canDefineDesignatedUserForHimself'],
    };
}
export function LoggedUserDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'userId': value['userId'],
        'name': value['name'],
        'email': value['email'],
        'roles': value['roles'],
        'language': value['language'],
        'canDefineDesignatedUserForHimself': value['canDefineDesignatedUserForHimself'],
    };
}
