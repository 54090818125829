export enum PathEnum {
  AUTH = 'auth',
  AUTHSSO = 'auth-sso',
  LOGIN = 'login',
  PRIVATE = 'private',
  DASHBOARD = 'dashboard',
  PROGETTI = 'projects',
  DETTAGLIO_PROGETTO = 'project-detail',
  CICLO_PASSIVO = 'passive-cycle',
  ELENCO = 'elenco',
  MONITORAGGIO = 'monitoraggio',
  GESTIONE_UTENTI = 'gestione-utenti',
  CDG = 'cdg',
  CL = 'cl',
  PL = 'pl',
  CRUD = 'crud',
  WFM = 'wfm',
  DELEGATE_MANAGEMENT = 'delegate-management',
  ACTIVE_CYCLE = 'active-cycle',
}
