/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { AssociaFattureProgettoDtoListFromJSON, AssociaFattureProgettoDtoListToJSON, CicloPassivoFieldsDtoFromJSON, ContractOrderOutputDtoFromJSON, ContractOrderOutputListDtoFromJSON, ContractOrderSearchDtoToJSON, CreateContractOrderAttivoDtoToJSON, FatturaEmessaPagedDtoFromJSON, FatturaEmessaSearchDtoToJSON, FatturaRicevutaPagedDtoFromJSON, FatturaRicevutaSearchDtoToJSON, ProjectLightInfoDtoFromJSON, } from '../models/index';
/**
 *
 */
export class FattureProgettoControllerApi extends runtime.BaseAPI {
    /**
     * Consente di creare o aggiornare una lista di associazioni di fatture a progetti
     * Associa una lista di fatture ad un progetto
     */
    associaFatturaProgettoRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['associaFattureProgettoDtoList'] == null) {
                throw new runtime.RequiredError('associaFattureProgettoDtoList', 'Required parameter "associaFattureProgettoDtoList" was null or undefined when calling associaFatturaProgetto().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/fatture/associa-fattura-progetto`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: AssociaFattureProgettoDtoListToJSON(requestParameters['associaFattureProgettoDtoList']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => AssociaFattureProgettoDtoListFromJSON(jsonValue));
        });
    }
    /**
     * Consente di creare o aggiornare una lista di associazioni di fatture a progetti
     * Associa una lista di fatture ad un progetto
     */
    associaFatturaProgetto(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.associaFatturaProgettoRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Crea un nuovo ordine
     */
    createCicloAttivoRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['createContractOrderAttivoDto'] == null) {
                throw new runtime.RequiredError('createContractOrderAttivoDto', 'Required parameter "createContractOrderAttivoDto" was null or undefined when calling createCicloAttivo().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/fatture/create-ciclo-attivo`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CreateContractOrderAttivoDtoToJSON(requestParameters['createContractOrderAttivoDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ContractOrderOutputDtoFromJSON(jsonValue));
        });
    }
    /**
     * Crea un nuovo ordine
     */
    createCicloAttivo(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.createCicloAttivoRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Consente di eliminare una fattura progetto tramite il suo ID
     * Elimina una fattura progetto
     */
    deleteFatturaProgettoRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['id'] == null) {
                throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling deleteFatturaProgetto().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/fatture/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Consente di eliminare una fattura progetto tramite il suo ID
     * Elimina una fattura progetto
     */
    deleteFatturaProgetto(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.deleteFatturaProgettoRaw(requestParameters, initOverrides);
        });
    }
    /**
     * Recupera tutti i possibili valori che le fatture possono assumere
     * Possibili valori nelle select del ciclo passivo
     */
    getFattureFieldsRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/fatture/fields`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => CicloPassivoFieldsDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera tutti i possibili valori che le fatture possono assumere
     * Possibili valori nelle select del ciclo passivo
     */
    getFattureFields(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getFattureFieldsRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Restituisce una lista di informazioni sui progetti da associare a fatture attive
     * OOttiene informazioni per select sui progetti da associare a fatture attive
     */
    getProjectLightInfoFattureAttiveRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/fatture/progetti-fatture-attive`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProjectLightInfoDtoFromJSON));
        });
    }
    /**
     * Restituisce una lista di informazioni sui progetti da associare a fatture attive
     * OOttiene informazioni per select sui progetti da associare a fatture attive
     */
    getProjectLightInfoFattureAttive(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getProjectLightInfoFattureAttiveRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Restituisce una lista di informazioni sui progetti da associare a fatture passive
     * Ottiene informazioni per select sui progetti da associare a fatture passive
     */
    getProjectLightInfoFatturePassiveRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/fatture/progetti-fatture-passive`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProjectLightInfoDtoFromJSON));
        });
    }
    /**
     * Restituisce una lista di informazioni sui progetti da associare a fatture passive
     * Ottiene informazioni per select sui progetti da associare a fatture passive
     */
    getProjectLightInfoFatturePassive(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getProjectLightInfoFatturePassiveRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Ricerca contratti
     */
    searchContractOrdersRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['contractOrderSearchDto'] == null) {
                throw new runtime.RequiredError('contractOrderSearchDto', 'Required parameter "contractOrderSearchDto" was null or undefined when calling searchContractOrders().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/fatture/search-orders-attivi`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ContractOrderSearchDtoToJSON(requestParameters['contractOrderSearchDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ContractOrderOutputListDtoFromJSON(jsonValue));
        });
    }
    /**
     * Ricerca contratti
     */
    searchContractOrders(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.searchContractOrdersRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Consente di ottenere tutte le fatture emesse utilizzando filtri dinamici
     * Ricerca fatture emesse con filtri
     */
    searchFattureEmesseRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['fatturaEmessaSearchDto'] == null) {
                throw new runtime.RequiredError('fatturaEmessaSearchDto', 'Required parameter "fatturaEmessaSearchDto" was null or undefined when calling searchFattureEmesse().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/fatture/emesse/search`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: FatturaEmessaSearchDtoToJSON(requestParameters['fatturaEmessaSearchDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => FatturaEmessaPagedDtoFromJSON(jsonValue));
        });
    }
    /**
     * Consente di ottenere tutte le fatture emesse utilizzando filtri dinamici
     * Ricerca fatture emesse con filtri
     */
    searchFattureEmesse(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.searchFattureEmesseRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Consente di ottenere tutte le fatture ricevute utilizzando filtri dinamici
     * Ricerca fatture ricevute con filtri
     */
    searchFattureRicevuteRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['fatturaRicevutaSearchDto'] == null) {
                throw new runtime.RequiredError('fatturaRicevutaSearchDto', 'Required parameter "fatturaRicevutaSearchDto" was null or undefined when calling searchFattureRicevute().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/fatture/ricevute/search`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: FatturaRicevutaSearchDtoToJSON(requestParameters['fatturaRicevutaSearchDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => FatturaRicevutaPagedDtoFromJSON(jsonValue));
        });
    }
    /**
     * Consente di ottenere tutte le fatture ricevute utilizzando filtri dinamici
     * Ricerca fatture ricevute con filtri
     */
    searchFattureRicevute(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.searchFattureRicevuteRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
